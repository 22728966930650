import React, { FC, useRef, memo, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import JobSelect from "./JobSelect";
import Menu from "./Menu";
import moment from 'moment';
import DayDescription from "./DayDescription";
import { DayAdditionalResourceComments } from "./DayAdditionalResourceComments";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { MasterCrewSchedulePaving, Maybe, Query, SuperIntendentPavingCalendar, WeatherContentData, WeatherData, MasterCrewSchedule, AddMasterCrewSchedulesPavingInput, JobInfoFullInput } from "../graphql/schema-types";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";
import JobSelectTrucking from "./JobSelectTrucking";
import { useImmer } from "use-immer";
import to from "await-to-js";
import { ModalContext } from "./Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "./Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { addPavingFromPavingManagement, addPavingResourcesToDay, addTruckingResourcesToDayFromPavingManagement } from "../redux/appSlice";
interface IDayProps {
  locationIndex: number;
  unix: number;
  weatherContent: WeatherContentData;
  weatherValues: WeatherData[];
}

interface CheckBoxes {
  grinderCheckBox: boolean,
  rtsSupportCheckBox: boolean,
}

type State = {
  resources: Maybe<MasterCrewSchedulePaving>[];
  qtySum: number;
  buttonDisabled: boolean;
  cannedCode: string;
  startDate: string;
  endDate: string
};

const getInitialPavingState = (): MasterCrewSchedulePaving & CheckBoxes => {
  return ({
    material: "",
    mixSubmital: "",
    plant: "",
    tonnage: null,
    oilTruck: null,
    notes: "",
    shift: "",
    qty: null,
    resourceID: "",
    resourceDescription: "",
    resourceType: "",
    tph: null,
    rtsSupport: "",
    bookTruckVendor: "",
    grinder4ft: null,
    grinder6ft: null,
    grinder7ft: null,
    grinder12ft: null,
    extraWork: "N",
    uts: "N",
    mixDesignApproval: "N",
    grinderCheckBox: false,
    rtsSupportCheckBox: true,
    addedFromPaving: false,
    updated: true,
    loadOutTime: "",
    addedDate: "",
  })
}

const getURLDay = (content: WeatherContentData, data: WeatherData[], shortForecast: string) => {
  let url;
  data?.forEach(d => {
    d?.shortForecast?.forEach(sf => {
      if (shortForecast.includes(sf as string)) {
        content?.weatherContentDataDay?.forEach(con => {
          if (con?.Key === d.photo) {
            url = con?.URL;
          }
        })
      }
    })
  })

  return `data:image/png;charset=utf-8;base64,${url}`;
}

const getURLNight = (content: WeatherContentData, data: WeatherData[], shortForecast: string) => {
  let url;
  data?.forEach(d => {
    d?.shortForecast?.forEach(sf => {
      if (shortForecast.includes(sf as string)) {
        content?.weatherContentDataNight?.forEach(con => {
          if (con?.Key === d.photo) {
            url = con?.URL;
          }
        })
      }
    })
  })
  return `data:image/png;charset=utf-8;base64,${url}`;
}

const Day: FC<IDayProps> = props => {
  const outerRef = useRef(null);

  const [pavingState, setPavingState] = useImmer(getInitialPavingState());

  const date = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].date);
  const day = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].day);
  const weekDay = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].weekDay);
  const temperatures = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.forecast);
  const resourceCodesByResourceType = useSelector((state: RootState) => state.app.resourceCodesByResourceType);
  const resourceIdIndexesByResourceType = useSelector((state: RootState) => state.app.resourceIdIndexesByResourceType);
  const customCrewsByJobNumber = useSelector((state: RootState) => state.app.customCrews);
  const isDayLocked = useSelector((state: RootState) => state.app.lockedDays.includes(props.unix));
  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const truckingResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix[props.unix]?.trucking?.truckingResources);
  const jobNumberInformations = useSelector((state: RootState) => state.app.jobNumberInformations);

  const modal = useContext(ModalContext);

  const dateFormat = "YYYY.MM.DD";
  const [selectedDates, setSelectedDates] = useState([moment(date).utc().format("YYYY.MM.DD")]);

  const [state, setState] = useImmer<State>({
    resources: [],
    qtySum: 0,
    buttonDisabled: true,
    cannedCode: "",
    startDate: moment().startOf('month').format(dateFormat),
    endDate: moment().startOf('month').add(3, 'months').format(dateFormat)
  });

  const [lockedDaysFromRefetch, setLockedDaysFromRefetch] = useState<Maybe<number>[]>([]);

  const client = useApolloClient();

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(1, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    });
    return _lockedDays;
  } 
  
  const reloadLockedDays = () => {
    refetchLockedDays();
    setLockedDaysFromRefetch(checkForLockedDays());
  }

  const pavingResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.paving.pavingResources)

  useEffect(() => {
    if (pavingResources != null) {
      if (pavingResources == null || pavingResources.length === 0) { return }

      const isGrinderUsed = pavingResources[0]?.grinder12ft != null
        || pavingResources[0]?.grinder4ft != null
        || pavingResources[0]?.grinder6ft != null
        || pavingResources[0]?.grinder7ft != null;

      setPavingState(draft => {
        draft.mixSubmital = pavingResources[0]?.mixSubmital?.trim();
        draft.material = pavingResources[0]?.material;
        draft.plant = pavingResources[0]?.plant;
        draft.tonnage = pavingResources[0]?.tonnage;
        draft.oilTruck = pavingResources[0]?.oilTruck;
        draft.shift = pavingResources[0]?.shift
        draft.notes = pavingResources[0]?.notes
        draft.rtsSupport = pavingResources[0]?.rtsSupport == null ? "" : pavingResources[0]?.rtsSupport as string
        draft.tph = pavingResources[0]?.tph
        draft.grinder4ft = pavingResources[0]?.grinder4ft! as number
        draft.grinder6ft = pavingResources[0]?.grinder6ft! as number
        draft.grinder7ft = pavingResources[0]?.grinder7ft! as number
        draft.grinder12ft = pavingResources[0]?.grinder12ft! as number
        draft.bookTruckVendor = pavingResources[0]?.bookTruckVendor as string
        draft.extraWork = pavingResources[0]?.extraWork
        draft.uts = pavingResources[0]?.uts
        draft.mixDesignApproval = pavingResources[0]?.mixDesignApproval
        draft.grinderCheckBox = isGrinderUsed
        draft.rtsSupportCheckBox = (pavingResources[0]?.rtsSupport !== "" && pavingResources[0]?.rtsSupport != null) ? true : false
        draft.addedFromPaving = pavingResources[0]?.addedFromPaving
        draft.updated = true
        draft.timeStamp = pavingResources[0]?.timeStamp
        draft.loadOutTime = pavingResources[0]?.loadOutTime
        draft.addedDate = pavingResources[0]?.addedDate == null || pavingResources[0]?.addedDate === "" ? "" : pavingResources[0]?.addedDate
      });
      return;
    }
  }, [pavingResources, setPavingState])

  const dispatch = useDispatch();

  const onSubmit = async () => {

    const [error, response] = await to(
      client.query({
        query: SUPER_INTENDENT_PAVING_CALENDAR_QUERY, variables: {
          where: {
            startDate: props.unix,
            endDate: props.unix,
          }
        }
      })
    );

    if (error == null) {
      let _lockedDays: Maybe<number>[] = [];
      response?.data.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
        if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
      })
    


    setTimeout(() => {
      if (_lockedDays.includes(props?.unix)) {
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="This day was locked while you were working. No more changes can be made to it."
            title="Locked day"
            yesNoButtons={false}
            onClose={() => {
              console.log("Locked Day")
            }}
          />
        })
      }else{
        let pavingResourcesForAllocation = [
          {addedDate: "",
addedFromPaving: false,
bookTruckVendor: "",
date: 1705968000000,
extraWork: "",
grinder4ft: 0,
grinder6ft: 0,
grinder7ft: 0,
grinder12ft: 0,
jobNumber: 7880,
loadOutTime: "",
locationIndex: 1,
material: "",
mixDesignApproval: "",
mixSubmital: "",
notes: "",
oilTruck: "",
plant: "",
qty: 1,
resourceDescription: "Foreman - Operator",
resourceID: "OFM",
resourceType: "Labor",
rtsSupport: "",
searchGSI_JobNumber: 7880,
shift: "",
timeStamp: "",
tonnage: 0,
tph: 0,
type: "",
uID: null,
updated: true,
uts: ""
},
{ 
addedDate: "",
addedFromPaving: false,
bookTruckVendor: "",
date: 1705968000000,
extraWork: "",
grinder4ft: 0,
grinder6ft: 0,
grinder7ft: 0,
grinder12ft: 0,
jobNumber: 7880,
loadOutTime: "",
locationIndex: 1,
material: "",
mixDesignApproval: "",
mixSubmital: "",
notes: "",
oilTruck: "",
plant: "",
qty: 1,
resourceDescription: "General Operator",
resourceID: "O",
resourceType: "Labor",
rtsSupport: "",
searchGSI_JobNumber: 7880,
shift: "",
timeStamp: "",
tonnage: 0,
tph: 0,
type: "",
uID: null,
updated: true,
uts: ""},
{ 
addedDate: "",
addedFromPaving: 
false,
bookTruckVendor: 
"",
date: 1705968000000,
extraWork: "",
grinder4ft: 0,
grinder6ft: 0,
grinder7ft: 0,
grinder12ft: 0,
jobNumber: 7880,
loadOutTime: "",
locationIndex: 1,
material: "",
mixDesignApproval: "",
mixSubmital: "",
notes: "",
oilTruck: "",
plant: "",
qty: 1,
resourceDescription: "4' Paver Small Paver / 8' Paver",
resourceID: "GILCREST",
resourceType: "Equipment",
rtsSupport: "",
searchGSI_JobNumber: 7880,
shift: "",
timeStamp: "",
tonnage: 0,
tph: 0,
type: "",
uID: null,
updated: true,
uts: ""}]


          let pavingResources: MasterCrewSchedulePaving[] = [];
          const truckingData: MasterCrewSchedule[] = [];
          const pavingData: AddMasterCrewSchedulesPavingInput[] = [];

          pavingResourcesForAllocation.forEach(pr => {
            pavingResources.push({
              material: pavingState.material,
              mixSubmital: pavingState.mixSubmital,
              plant: pavingState.plant,
              tonnage: pavingState.tonnage,
              oilTruck: pavingState.oilTruck,
              shift: pavingState.shift,
              resourceID: pr?.resourceID,
              resourceDescription: pr?.resourceDescription,
              resourceType: pr?.resourceType,
              jobNumber: jobNumber as number,
              searchGSI_JobNumber: jobNumber as number,
              date: pr?.date,
              locationIndex: props.locationIndex,
              qty: pr?.qty,
              notes: pavingState.notes,
              bookTruckVendor: pavingState?.bookTruckVendor as string,
              rtsSupport: pavingState?.rtsSupport == null ? "" : pavingState?.rtsSupport,
              tph: pavingState?.tph,
              grinder4ft: pavingState.grinder4ft as number,
              grinder6ft: pavingState.grinder6ft as number,
              grinder7ft: pavingState.grinder7ft as number,
              grinder12ft: pavingState.grinder12ft as number,
              mixDesignApproval: pavingState.mixDesignApproval,
              extraWork: pavingState.extraWork,
              uts: pavingState.uts,
              addedFromPaving: false,
              updated: true,
              timeStamp: moment().format(),
              loadOutTime: pavingState.loadOutTime as string,
              addedDate: (pavingState.addedDate != null && pavingState.addedDate !== "") ? pavingState.addedDate : moment().utc().format("YYYY-MM-DD")
            })
          })

          let _trkResources = truckingResources.filter(lr => lr?.operationType === "paving");
            let trkResourcesQty = _trkResources
              ?.map(tr => tr?.qty)
              ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

            const jobInfo: JobInfoFullInput[] = [];

            selectedDates?.forEach(date => {
              jobInfo.push(
                {
                  jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
                  searchGSI_JobNumber: jobNumberInformations?.jobNumber,
                  tableauGSI_Tableau: "tableau",
                  resourceName: "Paving Crew",
                  resourceQTY: 1,
                  resourceID: "PAV",
                  resourceType: "Labor",
                  date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
                  additionalResourcesComments: " ",
                  areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
                  description: " ",
                  foreman: " ",
                  jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
                  locationIndex: props.locationIndex,
                  pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
                  superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
                  pavingForeman: " ",
                  pavingSuperIntendent: " "
                },
                {
                  jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
                  searchGSI_JobNumber: jobNumberInformations?.jobNumber,
                  tableauGSI_Tableau: "tableau",
                  resourceName: "Trucking",
                  resourceQTY: trkResourcesQty,
                  resourceID: "TRK",
                  resourceType: "Equipment",
                  date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
                  additionalResourcesComments: " ",
                  areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
                  description: " ",
                  foreman: " ",
                  jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
                  locationIndex: props.locationIndex,
                  pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
                  superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
                  pavingForeman: " ",
                  pavingSuperIntendent: " ",
                },
                {
                  jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
                  searchGSI_JobNumber: jobNumberInformations?.jobNumber,
                  tableauGSI_Tableau: "tableau",
                  resourceName: "AC Paving Crew",
                  resourceQTY: 1,
                  resourceID: "ACP",
                  resourceType: "Labor",
                  date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
                  additionalResourcesComments: " ",
                  areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
                  description: " ",
                  foreman: " ",
                  jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
                  locationIndex: props.locationIndex,
                  pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
                  superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
                  pavingForeman: " ",
                  pavingSuperIntendent: " "
                },
              );

              _trkResources.forEach(tr => {
                truckingData.push({
                  date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
                  locationIndex: props.locationIndex,
                  broker: tr?.broker as string,
                  jobNumber: jobNumberInformations?.jobNumber as number,
                  tableauGSI_Tableau: "tableau",
                  searchGSI_JobNumber: jobNumberInformations?.jobNumber as number,
                  loadSite: tr?.loadSite as string,
                  material: tr?.material as string,
                  notes: " ",
                  operationType: tr?.operationType as string,
                  qty: tr?.qty as number,
                  shift: pavingState.shift as string,
                  type: tr?.type as string,
                  addedFromPaving: tr?.addedFromPaving != null ? tr?.addedFromPaving : true,
                  updated: tr?.updated != null ? tr?.updated : true,
                  timeStamp: tr?.timeStamp != null ? tr?.timeStamp : moment().format(),
                })
              })

              pavingResources.forEach(pr => {
                pavingData.push({
                  ...pr,
                  date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000
                })
              })

            });

            selectedDates.forEach(sd => {
              dispatch(addPavingFromPavingManagement({ jobInfoResources: jobInfo, locationIndex: props.locationIndex, jobInfoToUpdate: null, unix: (moment.utc(sd, "YYYY-MM-DD").unix()) * 1000 }))
              dispatch(addPavingResourcesToDay({ locationIndex: props.locationIndex, unix: (moment.utc(sd, "YYYY-MM-DD").unix()) * 1000, pavingResources: pavingData.filter(p => p.date === (moment.utc(sd, "YYYY-MM-DD").unix()) * 1000) }));
              dispatch(addTruckingResourcesToDayFromPavingManagement({ locationIndex: props.locationIndex, unix: (moment.utc(sd, "YYYY-MM-DD").unix()) * 1000, truckingResources: truckingData.filter(p => p?.date === (moment.utc(sd, "YYYY-MM-DD").unix()) * 1000) }));
            });
      }

    },3000)
    }
  }

  const onGrinder4ftChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;

    if (value < 0) { return }

    if (value.toString().length > 3) {
      return;
    }

    setPavingState(draft => {
      draft.grinder4ft = value as number;
    })
    onSubmit()
  }

  const onGrinder6ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;

    if (value < 0) { return }

    if (value.toString().length > 3) {
      return;
    }

    setPavingState(draft => {
      draft.grinder6ft = value as number;
    })
    onSubmit()
  }

  const onGrinder7ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;

    if (value < 0) { return }

    if (value.toString().length > 3) {
      return;
    }

    setPavingState(draft => {
      draft.grinder7ft = value as number;
    })
    onSubmit()
  }

  const onGrinder12ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;

    if (value < 0) { return }

    if (value.toString().length > 3) {
      return;
    }

    setPavingState(draft => {
      draft.grinder12ft = value as number;
    })
    onSubmit()
  }
  
  return (
    <div ref={outerRef} className="app">
      <Menu outerRef={outerRef} locationIndex={props.locationIndex} unix={props.unix} />
      <div className="Tabble_day_column">
        <div className="Table_head" style={{ backgroundColor: (day === 6 || day === 0) ? 'rgba(245, 123, 32, 0.6)' : '#a5c8c1' }} >
          <div className="Inner_border">
            <div className="Day_Date">{date}</div>
            <div className="Forecast">
              <div className="Forecast_Day">
                {temperatures[temperatures.length < 3 ? 0 : 1] != null ?
                  < img className={"Forecast_Icon"}
                    src={getURLDay(props?.weatherContent as WeatherContentData, props?.weatherValues as WeatherData[], temperatures[temperatures.length < 3 ? 0 : 1]?.shortForecast)}
                    alt={temperatures[temperatures.length < 3 ? 0 : 1]?.shortForecast} /> : null}
                <div>
                  {
                    temperatures[temperatures.length < 3 ? 0 : 1]?.temperature != null
                      ? temperatures[temperatures.length < 3 ? 0 : 1]?.temperature + "°F"
                      : null
                  }
                </div>
              </div>
              <div className="Forecast_Night">
                {temperatures[temperatures.length < 3 ? 1 : 2] != null ?
                  <img className={"Forecast_Icon"}
                    src={getURLNight(props?.weatherContent as WeatherContentData, props?.weatherValues as WeatherData[], temperatures[temperatures.length < 3 ? 1 : 2]?.shortForecast)}
                    alt={temperatures[temperatures.length < 3 ? 1 : 2]?.shortForecast} /> : null}
                <div>
                  {
                    temperatures[temperatures.length < 3 ? 1 : 2]?.temperature != null
                      ? temperatures[temperatures.length < 3 ? 1 : 2]?.temperature + "°F"
                      : null
                  }
                </div>
              </div>
            </div>
            <div className="Day_Day">{weekDay} {(isDayLocked === true || lockedDaysFromRefetch.includes(props.unix)) ? <i className="fa fa-lock" aria-hidden="true"></i> : null}</div>
            <div className="Bottom_Footer">
              <div className="Resource">Resource</div>
              <div className="QTY">QTY</div>
            </div>
          </div>
        </div>

      </div>

      <div style={{ height: "665px", display: "flex", flexDirection: "column" }}>

        <div>
          <div className="Column_title">
            <DayDescription
              locationIndex={props.locationIndex}
              unix={props.unix}
            />
          </div>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
            return (
              <div key={index} className="Column_row">
                <JobSelect
                  onReloadLockedDays={() => reloadLockedDays()}
                  resourceCodes={resourceCodesByResourceType["Labor"]}
                  resourceIdIndexes={resourceIdIndexesByResourceType["Labor"]}
                  indexPos={index}
                  locationIndex={props.locationIndex}
                  unix={props.unix}
                  crews={customCrewsByJobNumber}
                  resourceType={"labor"}
                />
              </div>
            )
          })}
        </div>

        <div className="grinder">
                    <div className="Row">
                      <div className="Row_Usage">4ft: </div>
                      <input
                        type="text"
                        value={pavingState.grinder4ft ?? ""}
                        onChange={(e) => onGrinder4ftChange(e)} />
                    </div>
                    <div className="Row">
                      <div className="Row_Usage">6ft: </div>
                      <input
                        type="text"
                        value={pavingState.grinder6ft ?? ""}
                        onChange={(e) => onGrinder6ftChange(e)} />
                    </div>
                    <div className="Row">
                      <div className="Row_Usage">7ft: </div>
                      <input
                        type="text"
                        value={pavingState.grinder7ft ?? ""}
                        onChange={(e) => onGrinder7ftChange(e)} />
                    </div>
                    <div className="Row">
                      <div className="Row_Usage">12ft:</div>
                      <input
                        type="text"
                        value={pavingState.grinder12ft ?? ""}
                        onChange={(e) => onGrinder12ftChange(e)}
                      />
                    </div>
                  </div>

        <div className="Eq_req" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
            return (
              <div key={index} className="Column_row">
                <JobSelect
                  onReloadLockedDays={() => reloadLockedDays()}
                  resourceCodes={resourceCodesByResourceType["Equipment"]}
                  resourceIdIndexes={resourceIdIndexesByResourceType["Equipment"]}
                  indexPos={index}
                  locationIndex={props.locationIndex}
                  unix={props.unix}
                  resourceType={"equipment"}
                />
              </div>
            )
          })}
          <div className="Eq_req" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {[10].map((_, index) => {
            return (
              <div key={index + 10} className="Column_row">
                <JobSelectTrucking
                  onReloadLockedDays={() => reloadLockedDays()}
                  resourceCodes={resourceCodesByResourceType["Equipment"]}
                  resourceIdIndexes={resourceIdIndexesByResourceType["Equipment"]}
                  indexPos={index + 10}
                  locationIndex={props.locationIndex}
                  unix={props.unix}
                  resourceType={"equipment"}
                />
              </div>
            )
          })}
          <DayAdditionalResourceComments
            locationIndex={props.locationIndex}
            unix={props.unix}
          />
        </div>
      </div>
      </div>

    </div >
  )
};

export default memo(Day);