import * as XLSX from "xlsx";

export function generateExcelFile(truckingByDate: any) {
  const data = [];
  const column = ['Date', 'Vendor', 'Type', 'Material', 'Load Site', 'QTY', 'Shift', 'Notes'];
  data.push(column);

  for (const fecha in truckingByDate) {
    const trucks = truckingByDate[fecha];

    if (trucks.length > 0) {
      const row = [fecha];

      trucks.forEach((truck: any) => {
        row.push(truck.broker, truck.type, truck.material, truck.loadSite, truck.qty, truck.shift, truck.notes);
      });

      data.push(row);
    }
  }

  if (data.length > 1) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trucking Data');

    XLSX.writeFile(workbook, 'trucking_data.xlsx');
  }
}
