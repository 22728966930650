/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { Maybe, JobInfo, ResourceCode, CustomCrew, CustomCrewResourceComposition, JobInfoLocations, JobNumberInformation, MasterCrewSchedule, MasterCrewSchedulePaving, SuperIntendentPavingCalendarDatas, MaterialData, SuperIntendentPavingCalendar, EquipmentResource, EquipmentManagementData, EquipmentResourceManagementData, TruckingData, SuperIntendentPavingCalendarItemInput, PavingForeman, IncompleteSuperIntendentPavingCalendarDatas, PavingCalendarInput, Resource, EquipmentManagementSubCategoryData, EquipmentSubcategories, RemoteEquipment } from "../graphql/schema-types";
import { toMap } from "../utils/toMap";
import { toMultiMap } from "../utils/toMultiMap";
import { getTokenFromCookie, getIdTokenFromCookie, getRefreshTokenFromCookie } from "../auth/getTokenFromCookie";
import { AppThunk } from "./store";
import { settings } from "../config/settings";
import to from "await-to-js";

export type AuthState = {
  token: string | null | undefined;
  id_token: string | null | undefined;
  refresh_token: string | null | undefined;
}

export interface StartState {
  jobNumber: number | "",
  beginDate: string,
  days: number | "",
  validJobNumber: boolean | null,
  validBeginDate: boolean | null,
  validDays: boolean | null,
}

export interface LaborState {
  jobInfos: Maybe<JobInfo>[];
}

export interface EquipState {
  jobInfos: Maybe<JobInfo>[];
}

export interface TruckingState {
  truckingResources: Maybe<MasterCrewSchedule>[];
}

export interface PavingState {
  pavingResources: Maybe<MasterCrewSchedulePaving>[];
}

export interface DayState {
  name?: string;
  locationIndex?: number;
  date: string;
  unix: number;
  weekDay: string;
  day: number;
  labor: LaborState;
  equip: EquipState;
  description: string;
  additionalResourceComments: string;
  trucking: TruckingState;
  paving: PavingState
  forecast: Forecast[];
  selectedResources: { [resourceID: string]: boolean }
}

export interface LocationState {
  name?: string;
  activityDescription?: string;
  days: number[],
  daysByUnix: { [unix: number]: DayState; }
}

export interface mDayByLocation {
  locationIndex?: number;
  dayUnix?: number;
}

type Locations = {
  [NoOfDataSet: number]: LocationState;
}

export interface ScheduleState {
  locations: Locations;
}

type ResourceCodesByResourceType = { [resourceType: string]: Maybe<ResourceCode>[] };

type JobInfosByStartDate = { [startDate: number]: Maybe<JobInfo>[] };

type ResourceCodesByResourceID = { [resourceID: string]: Maybe<ResourceCode> };

type ResourceIdIndexesByResourceType = { [resourceType: string]: { [resourceId: string]: number; } };

type ModifiedDayByLocation = {
  [locationIndex: number]: {
    [dayUnix: number]: string[]
  }
}

type JobInfosByLocationAndStartDate = {
  [location: number]: {
    [startDate: number]: Maybe<JobInfo>[];
  }
}

type ModifiedLocationInfo = {
  [location: number]: {
    locationName?: string;
    activityDescription?: string;
  }
}

// type EquipmentManagement = {
//   resources: Maybe<EquipmentResourceManagementData>[];
// }

type PavingDayState = {
  date: number;
  isDayBlocked: boolean;
  items: Maybe<SuperIntendentPavingCalendarDatas>[];
  jobNumbersBlocked: number[];
  noWorkForForeman: string[];
  jobNumbersRedBordered: number[];
  equipment: Maybe<EquipmentResourceManagementData>[];
  equipmentSubCategory: Maybe<EquipmentManagementSubCategoryData>[];
  itemsToRemove: SuperIntendentPavingCalendarItemInput[];
}

export interface PavingModule {
  [date: number]: PavingDayState;
}

type PavingModuleUser = {
  name: string;
  email: string;
  groups: string[];
  isInPavingSupers: boolean | null;
  isInGeneralPavingSupers: boolean | null;
  isDisabled: boolean | null;
}
export interface AppState {
  auth: AuthState;
  start: StartState;
  schedule: ScheduleState;
  lockedDays: Maybe<number>[];
  resourceCodes: Maybe<ResourceCode>[];
  resourceCodesByResourceType: ResourceCodesByResourceType;
  resourceCodesByResourceID: ResourceCodesByResourceID;
  jobInfos: Maybe<JobInfo>[];
  jobInfosByStartDate: JobInfosByStartDate;
  jobInfosByLocationAndStartDate: JobInfosByLocationAndStartDate;
  resourceIdIndexesByResourceType: ResourceIdIndexesByResourceType;
  lastDay: DayState | null;
  copyDay: DayState | null;
  copyTrucking: Maybe<MasterCrewSchedule>[] | null;
  copyTruckingJobInfo: JobInfo | null;
  copyPaving: Maybe<MasterCrewSchedulePaving>[] | null;
  copyPavingJobInfo: JobInfo | null;
  copyPavingTruckingResources: Maybe<MasterCrewSchedule>[] | null;
  copyPavingTruckingResourceJobInfo: JobInfo | null;
  customCrews: Maybe<CustomCrew>[];
  materialDatas: Maybe<MaterialData>[];
  jobNumberInformations: Maybe<JobNumberInformation>;
  modifiedDayByLocation: ModifiedDayByLocation;
  modifiedLocation: Number[];
  modifiedLocationInfo: ModifiedLocationInfo;
  trucking: Maybe<MasterCrewSchedule>[];
  paving: Maybe<MasterCrewSchedule>[];
  forecast: Forecast[];
  pavingModule: PavingModule;
  pavingModuleUser: PavingModuleUser;
  equipmentResources: Maybe<EquipmentResource>[];
  equipmentSubcategories: Maybe<EquipmentSubcategories>;
  truckingDatas: Maybe<TruckingData>[] | undefined;
  pavingForemans: Maybe<PavingForeman>[] | undefined;
  fullDayPreview: SheetRowDayPreview[];
  pavingModuleIncompleteItems: Maybe<IncompleteSuperIntendentPavingCalendarDatas>[];
  remoteEquipmentList: Maybe<RemoteEquipment>[];
}

export const getInitialState = (token?: string | null | undefined, id_token?: string | null | undefined, refresh_token?: string | null | undefined): AppState => ({
  auth: {
    token: token === undefined ? getTokenFromCookie() : token,
    id_token: id_token === undefined ? getIdTokenFromCookie() : id_token,
    refresh_token: refresh_token === undefined ? getRefreshTokenFromCookie() : refresh_token
  },
  start: {
    jobNumber: "",
    beginDate: "",
    days: "",
    validJobNumber: null,
    validBeginDate: null,
    validDays: null,
  },
  schedule: {
    locations: {},
  },
  lockedDays: [],
  resourceCodes: [],
  resourceCodesByResourceType: {},
  resourceCodesByResourceID: {},
  jobInfos: [],
  jobInfosByStartDate: {},
  jobInfosByLocationAndStartDate: {},
  resourceIdIndexesByResourceType: {},
  modifiedDayByLocation: {},
  modifiedLocation: [],
  modifiedLocationInfo: {},
  lastDay: null,
  copyDay: null,
  copyTrucking: null,
  copyTruckingJobInfo: null,
  copyPaving: null,
  copyPavingJobInfo: null,
  copyPavingTruckingResources: null,
  copyPavingTruckingResourceJobInfo: null,
  customCrews: [],
  materialDatas: [],
  jobNumberInformations: {
    address: "",
    coordinateLat: "",
    coordinateLong: "",
    jobName: "",
    jobNumber: 0,
    superIntendentCode: "",
    superIntendentName: ""
  },
  trucking: [],
  paving: [],
  forecast: [],
  pavingModule: {},
  pavingModuleUser: {
    name: "",
    email: "",
    groups: [],
    isInPavingSupers: null,
    isInGeneralPavingSupers: null,
    isDisabled: null
  },
  equipmentResources: [],
  equipmentSubcategories: {
    equipmentsForCheckbox: "",
    equipmentsForInput: ""
  },
  truckingDatas: [],
  fullDayPreview: [],
  pavingForemans: [],
  pavingModuleIncompleteItems: [],
  remoteEquipmentList: []
});

const getMomentDays = (startDate: string, days: number): Moment[] => {
  const start = moment.utc(startDate, "YYYY-MM-DD");
  const momentDays: Moment[] = [start];
  for (let i = 0; i < days - 1; i++) {
    momentDays.push(start.clone().add(i + 1, "day"))
  }
  return momentDays;
}

const createDay = (md: Moment): DayState => {
  return ({
    date: md.format("MM/DD/YYYY"),
    unix: md.valueOf(),
    weekDay: md.format("ddd"),
    day: md.weekday(),
    labor: {
      jobInfos: [],
    },
    equip: {
      jobInfos: [],
    },
    trucking: {
      truckingResources: []
    },
    paving: {
      pavingResources: []
    },
    forecast: [],
    description: "",
    additionalResourceComments: "",
    selectedResources: {}
  })
}

const clearDayForLockedDays = (itemFromDay: DayState, md: Moment) => {
  const _itemFromDay = createDay(md);
  const isPavingResInJobInfo = true
    ? itemFromDay.labor.jobInfos.filter(job => job?.resourceID === "PAV").length > 0
    : false;

  const hasTruckingResWithOperationTypePaving = true
    ? itemFromDay.trucking.truckingResources.filter(trucking => trucking?.operationType === "paving").length > 0
    : false;

  if (itemFromDay.paving.pavingResources.length > 0 && isPavingResInJobInfo === true) {
    _itemFromDay.paving.pavingResources.push(...itemFromDay.paving.pavingResources);
    _itemFromDay.labor.jobInfos.push(itemFromDay.labor.jobInfos?.filter(job => job?.resourceID === "PAV")[0] as JobInfo);
    if (hasTruckingResWithOperationTypePaving === true) {
      const truckingResources = itemFromDay.trucking.truckingResources?.filter(trucking => trucking?.operationType === "paving");
      truckingResources?.forEach(tr => {
        _itemFromDay.trucking.truckingResources.push(tr);
      });
      const truckingJobInfoResource = itemFromDay.equip.jobInfos?.filter(job => job?.resourceID === "TRK");
      truckingJobInfoResource[0]!.resourceQTY = truckingResources
        ?.map(tr => tr?.qty)
        ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;
      _itemFromDay.equip.jobInfos.push(...truckingJobInfoResource,);
    }
  }
  return _itemFromDay;
}


// const createCopyFullDayObjectForPaste = (resourcesFromCopyDay: DayState, md: Moment, dayResourcesBeforePaste: DayState, locationIndex: number, isDayLocked: boolean) => {
//   const _itemFromDay = resourcesFromCopyDay;

//   // resources from copy day
//   const truckingResourcesWithOpTypePaving = resourcesFromCopyDay.trucking.truckingResources?.filter(tr => tr?.operationType === "paving");
//   const truckingResourcesWithoutOpTypePaving = resourcesFromCopyDay.trucking.truckingResources?.filter(tr => tr?.operationType !== "paving");
//   const equipJobInfos = resourcesFromCopyDay?.equip.jobInfos;
//   const equipJobInfosWithoutTrk = resourcesFromCopyDay?.equip.jobInfos.filter(ji => ji?.resourceID !== "TRK");
//   const laborJobInfosWithoutPav = resourcesFromCopyDay?.labor.jobInfos.filter(ji => ji?.resourceID !== "PAV");
//   const hasCopyDayTrucking = resourcesFromCopyDay.equip.jobInfos.filter(ji => ji?.resourceID === "TRK").length > 0 ? true : false;
//   const hasCopyDayPaving = resourcesFromCopyDay.labor.jobInfos.filter(ji => ji?.resourceID === "PAV").length > 0 ? true : false;
//   const isLocationIndexForPaving = locationIndex > 2 ? false : true;

//   // resources from current day before pasting
//   const truckingResourcesCurrentDayWithOpTypePav = dayResourcesBeforePaste.trucking.truckingResources.filter(tr => tr?.operationType === "paving");
//   const pavingResourcesCurrentDay = dayResourcesBeforePaste.paving.pavingResources;
//   const pavingJobInfoCurrentDay = dayResourcesBeforePaste.labor.jobInfos.filter(ji => ji?.resourceID === "PAV")[0];
//   const hasCurrentDayTrucking = dayResourcesBeforePaste.equip.jobInfos.filter(ji => ji?.resourceID === "TRK").length > 0 ? true : false;

//   if (isLocationIndexForPaving === false || isDayLocked === true) {
//     if (pavingJobInfoCurrentDay != null) {
//       _itemFromDay.labor.jobInfos = resourcesFromCopyDay?.labor.jobInfos;
//     }
//     else {
//       _itemFromDay.labor.jobInfos = laborJobInfosWithoutPav;
//     }
//   }
//   else {
//     _itemFromDay.labor.jobInfos = resourcesFromCopyDay?.labor.jobInfos;
//   }

//   if (hasCopyDayPaving === false) {
//     _itemFromDay.labor.jobInfos.push(pavingJobInfoCurrentDay);
//     _itemFromDay.paving.pavingResources = pavingResourcesCurrentDay;
//   }

//   if (hasCopyDayPaving === true && (isLocationIndexForPaving === false || isDayLocked === true) && pavingResourcesCurrentDay.length === 0) {
//     _itemFromDay.paving.pavingResources = [];
//   }

//   if (isLocationIndexForPaving === false || isDayLocked === true) {
//     if (truckingResourcesWithoutOpTypePaving.length > 0) {
//       _itemFromDay.equip.jobInfos = equipJobInfos;
//     }
//     else {
//       _itemFromDay.equip.jobInfos = equipJobInfosWithoutTrk;
//     }
//   }
//   else {
//     _itemFromDay.equip.jobInfos = equipJobInfos;
//   }

//   if (hasCopyDayTrucking === false && hasCurrentDayTrucking === true) {
//     _itemFromDay.trucking.truckingResources = truckingResourcesCurrentDayWithOpTypePav;
//   }

//   if (truckingResourcesWithoutOpTypePaving.length > 0) {

//     if (truckingResourcesCurrentDayWithOpTypePav.length === 0) {
//       _itemFromDay.trucking.truckingResources = truckingResourcesWithoutOpTypePaving;
//     }
//     else {
//       truckingResourcesWithoutOpTypePaving.forEach(tr => {
//         _itemFromDay.trucking.truckingResources.push(tr);
//       })
//     }
//   }

//   if (truckingResourcesWithOpTypePaving.length > 0) {
//     if (isDayLocked === false && isLocationIndexForPaving === true) {
//       truckingResourcesWithOpTypePaving.forEach(tr => {
//         _itemFromDay.trucking.truckingResources.push(tr);
//       })
//     }
//     if (_itemFromDay.trucking.truckingResources.length === 0) {
//       _itemFromDay.trucking.truckingResources = [];
//     }
//   }

//   const truckingJobInfo = _itemFromDay.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")[0];

//   const qtySumFromTruckingResourcesFromDay = _itemFromDay.trucking.truckingResources
//     ?.map(tr => tr?.qty)
//     ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

//   if (truckingJobInfo != null) {
//     truckingJobInfo.resourceQTY = qtySumFromTruckingResourcesFromDay;
//   }

//   return _itemFromDay;
// }

const createLocation = (start: StartState, name: string, activityDescription: string): LocationState => {
  const momentDays = getMomentDays(start.beginDate, parseInt(`${start.days}`));
  const days: DayState[] = momentDays.map((md, i) => createDay(md));

  return ({
    name,
    activityDescription,
    days: days.map(day => day.unix),
    daysByUnix: toMap(days, day => day.unix),
  });
}

export const getMomentDaysForPavingModule = (startDate: string, days: number) => {
  const start = moment.utc(startDate, "YYYY-MM-DD");
  const momentDays: Moment[] = [start];
  for (let i = 0; i < days - 1; i++) {
    momentDays.push(start.clone().add(i + 1, "day"))
  }
  return momentDays;
}


export const createPavingModuleDay = (md: Moment): PavingDayState => {
  return ({
    date: md.valueOf(),
    isDayBlocked: false,
    items: [],
    jobNumbersBlocked: [],
    noWorkForForeman: [],
    jobNumbersRedBordered: [],
    equipment: [],
    equipmentSubCategory: [],
    itemsToRemove: [],
  })
}

export const createPavingModule = (numberOfDays: number, firstDay?: string) => {
  const firstDayOfCurrentWeek = moment().startOf('week').format("YYYY-MM-DD");
  let momentDaysForPavingModule = [];

  if (firstDay == null) {
    momentDaysForPavingModule = getMomentDaysForPavingModule(firstDayOfCurrentWeek, numberOfDays);
  }
  else {
    momentDaysForPavingModule = getMomentDaysForPavingModule(firstDay, numberOfDays);
  }

  const days = momentDaysForPavingModule.map((md, i) => createPavingModuleDay(md));

  return toMap(days, day => day?.date);
}

type InitPayload = {
  jobInfos: Maybe<JobInfo>[];
  resourceCodes: Maybe<ResourceCode>[];
  jobInfoLocationsData: Maybe<JobInfoLocations>[];
  pavingCrewData: Maybe<MasterCrewSchedulePaving>[];
  truckingCrewData: Maybe<MasterCrewSchedule>[];
  materialDatas: Maybe<MaterialData>[];
  lockedDays: Maybe<number>[];
};

type InitPavingModule = {
  superIntendentDatas: Maybe<SuperIntendentPavingCalendarDatas>[]
}

type AddBlockedJobNumbersToPavingModule = {
  pavingCalendarItems: Maybe<SuperIntendentPavingCalendar>[]
}

type CustomCrewsPayload = {
  customCrews: Maybe<CustomCrew>[];
}

type JobNumberInformationPayload = {
  jobNumberInformations: Maybe<JobNumberInformation>;
}

type ClearDayPayload = {
  locationIndex: number;
  unix: number;
  lockedDays?: Maybe<number>[];
}

type ChangeDoubledShiftPlayload = {
  stateName: string;
  locationIndex: number;
  day: number;
}

type JobChangePayload = {
  locationIndex: number;
  unix: number;
  indexPos: number | null;
  rc?: Maybe<ResourceCode>;
  qty?: number | "";
  resourceType?: string;
}

type RemoveJobPayload = {
  locationIndex: number;
  unix: number;
  indexPos: number | null;
  resourceType: "labor" | "equipment";
  resourceID: string;
}

type AddPavingFromPavingCalendarResources = {
  locationIndex: number;
  pavingResources: Maybe<MasterCrewSchedulePaving>[] | null;
  jobInfoResources: Maybe<JobInfo>[] | null;
  truckingResources: Maybe<MasterCrewSchedule>[] | null;
  jobInfoToUpdate: Maybe<JobInfo>[] | null;
}

type DeleteResourcesFromJIAndTrucking = {
  locationIndex: number;
  unix: number;
  truckingResources: PavingCalendarInput[] | null;
}

type AddPavingFromPavingManagement = {
  locationIndex: number;
  jobInfoResources: Maybe<JobInfo>[] | null;
  jobInfoToUpdate: Maybe<JobInfo>[] | null;
  unix: number;
}

type AddResourcesToDay = {
  locationIndex: number;
  unix: number;
  resources: Maybe<CustomCrewResourceComposition>[];
}

type AddDescriptionToDay = {
  locationIndex: number;
  unix: number;
  description: string;
}

type LocationInfo = {
  locationIndex: number;
  activityDescription?: string;
  location?: string;
}

type AddAdditionalResourceCommentsToDay = {
  locationIndex: number;
  unix: number;
  additionalResourceComments: string;
}

type AddTruckingResourcesToDay = {
  locationIndex: number;
  unix: number;
  truckingResources: Maybe<MasterCrewSchedule>[];
}

type RemoveTruckingResourcesToDay = {
  locationIndex: number;
  unix: number;
}

type AddPavingResourcesToDay = {
  locationIndex: number;
  unix: number;
  pavingResources: Maybe<MasterCrewSchedulePaving>[];
}

type AddTruckingQTYFromPavingToDay = {
  locationIndex: number;
  unix: number;
  qty: number;
}

type Coordinates = {
  x: number;
  y: number;
}

type Forecast = {
  detailedForecast: string;
  endTime: string;
  icon: string;
  isDaytime: boolean;
  name: string;
  number: number;
  shortForecast: string;
  startTime: string;
  temperature: number;
  temperatureTrend: string;
  temperatureUnit: string;
  windDirection: string;
  windSpeed: string;
}

type ChangePavingItemToAnotherDay = {
  date: number;
  newDate: number;
  jobNumber: number;
  locationIndex: number;
  shift: string;
}

type ChangePavingItemForeman = {
  pavingForeman: string;
  date: number;
  jobNumber: number;
  locationIndex: number;
  shift: string;
}

type AddPavingModuleUser = {
  name: string,
  email: string;
  groups: string[];
  isInPavingSupers: boolean
  isInGeneraPavingSupers: boolean,
  isDisabled: boolean
}

type UpdateJobNumberStatusFromDay = {
  unix: number,
  jobNumber: number,
  lock: boolean
}

export type UpdateBlockedForemanFromCurrentDay = {
  unix: number,
  lock: boolean,
  foremanName: string
}

type UpdateBlockedForemanFromDay = {
  unix: number,
  foremans: UpdateBlockedForemanFromCurrentDay[]
}

type UpdateJobsNumbersRedBordered = {
  unix: number,
  jobNumber: number,
  status: boolean
}

type UpdateDayStatusForPavingModule = {
  unix: number,
  isDayBlocked: boolean
}

type AddDayStatusForPavingModule = {
  superIntendentPavingCalendarItems: Maybe<SuperIntendentPavingCalendar>[]
}

type AddEquipmentResources = {
  equipmentResources: Maybe<EquipmentResource>[]
}

type AddEquipmentSubcategories = {
  equipmentsForInput?: Maybe<string> | undefined;
  equipmentsForCheckbox?: Maybe<string> | undefined;
}

type AddEquipmentToDay = {
  equipmentManagementResources: Maybe<EquipmentManagementData>[],
  equipmentSubcategoryData?: Maybe<EquipmentManagementSubCategoryData>[]
}

type UpdateEquipmentToDay = {
  dates: number[],
  jobNumber: number,
  equipmentManagementResources: Maybe<EquipmentResourceManagementData>[],
  equipmentSubcategoryData: Maybe<EquipmentManagementSubCategoryData>[]
}

type AddPavingForemans = {
  pavingForemans: Maybe<PavingForeman>[] | undefined;
}

type UpdateEquipmentResourcesList = {
  date: number;
  newDate: number;
  nrOfItemsWithSameJobNumber: number;
  jobNumber: number;
  shift: string;
}

type AddTruckingAndMaterialDatas = {
  truckingDatas: Maybe<TruckingData>[] | undefined;
  materialDatas: Maybe<MaterialData>[] | undefined;
}

type UpdateTruckingAndMaterialDatas = {
  truckingDatas?: Maybe<TruckingData>[] | undefined;
  materialDatas?: Maybe<MaterialData>[] | undefined;
}

type UpdateForemanForItemsFromCalendar = {
  foremanNameForUpdate: string;
  foremanToDelete: string;
}

type SheetRowDayPreview = {
  date: number,
  jobNumber: number,
  locatinIndex: number,
  superIntendent: string,
  tonnage: number | null | undefined,
  broker: string,
  typeOfTrucks: string,
  nrOfTrucks: number | null | undefined,
  shift: string,
  plant: string,
  loadOutTime: string,
  material: string,
  updateJobInfoTable: boolean,
  updateMasterCrewSchedulePavingTable: boolean,
  updateMasterCrewScheduleTable: boolean,
  jobName: string,
  pavingSuper: string,
  areaManager: string,
  pmpe: string,
  pavingForeman: string,
  pavingSuperIntendent: string,
  jobMap: string,
  tph: number,
  uts: string,
  oilTruk: string,
  extrawork: string,
  bookTruckVendor: string,
  crewMakeLab: number,
  crewMakeOp: number,
  grinder4ft: number,
  grinder6ft: number,
  grinder7ft: number,
  grinder12ft: number,
  resourcesOp: [Resource],
  resourcesLab: [Resource],
  mixDesignApproval: string,
  rtsSupport: string,
  mixSubmital: string
}

type UpdateJobMap = {
  startDate: number,
  jobNumber: number,
  jobMap: string
}

type AddIncompleteSuperCalendarPavingDatas = {
  incompleteItems: Maybe<IncompleteSuperIntendentPavingCalendarDatas>[]
}

type RemoteEquipmentList = {
  list: Maybe<RemoteEquipment>[];
}

type LockedDays = {
  lockedDays: Maybe<number>[];
}

export const appSlice = createSlice({
  name: 'app',
  initialState: getInitialState(),
  reducers: {
    reset: () => {
      return getInitialState();
    },
    addToken: (state, action: PayloadAction<Partial<AuthState>>) => {
      state.auth.id_token = action.payload.id_token;
      state.auth.refresh_token = action.payload.refresh_token;
    },
    refreshAuthToken: (state, action: PayloadAction<Partial<AuthState>>) => {
      state.auth.id_token = action.payload.id_token;
    },
    removeToken: state => {
      delete state.auth.token;
      delete state.auth.id_token;
    },
    login: (state, action: PayloadAction<AuthPayload>) => {
      state.auth = action.payload;
    },
    logout: () => {
      return ({
        ...getInitialState(null, null, null),
      });
    },
    start: (state, action: PayloadAction<StartPayload>) => {
      state.start = action.payload;
    },
    init: (state, action: PayloadAction<InitPayload>) => {
      state.schedule.locations = []
      const { jobInfos, resourceCodes, jobInfoLocationsData, pavingCrewData, truckingCrewData, materialDatas, lockedDays } = action.payload;

      const _locations = jobInfoLocationsData.map(ji => ji != null ? ji?.locationIndex : null)
      state.jobInfos = [...jobInfos].sort((a, b) => a?.resourceID! < b?.resourceID! ? -1 : 1);

      if (_locations.length === 0) {
        state.schedule.locations[1] = createLocation(state.start, "Location 1", "");
      }

      if (_locations.length !== 0) {
        if (!_locations?.includes(1)) {
          state.schedule.locations[1] = createLocation(state.start, "Location 1", "");
        }
        if (!_locations?.includes(2)) {
          state.schedule.locations[2] = createLocation(state.start, "Location 2", "");
        }
      }

      (jobInfoLocationsData ?? [])?.forEach(jil => {
        if (jil == null) { return }
        state.schedule.locations[jil?.locationIndex ?? 1] = createLocation(state.start, jil.name ?? "", jil.activityDescription ?? "");
      })

      state.jobInfos?.forEach(ji => {
        let type: "labor" | "equip" = ji?.resourceType === "Labor" ? "labor" : "equip";

        if (state.schedule.locations?.[(ji?.locationIndex ?? 1)] == null) {
          state.schedule.locations[(ji?.locationIndex ?? 1)] = createLocation(state.start, (`${ji?.locationIndex ?? 1}`), "");
        }

        state.schedule.locations?.[(ji?.locationIndex ?? 1)]?.daysByUnix[ji?.date ?? 0]?.[type].jobInfos.push(ji);

        if (state.schedule.locations?.[(ji?.locationIndex ?? 1)]?.daysByUnix?.[ji?.date ?? 0]?.selectedResources != null) {
          if (ji?.resourceID as string !== "ACP") {

            state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
          }
        }

        if (
          state.schedule.locations?.[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0] != null
          && state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0]?.description === ""
          && ji?.description !== " "
        ) {
          state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].description = ji?.description!
        }

        if (
          state.schedule.locations?.[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0] != null
          && state.schedule.locations?.[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0]?.additionalResourceComments === ""
          && ji?.additionalResourcesComments !== " "
        ) {
          state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].additionalResourceComments = ji?.additionalResourcesComments!
        }
      });

      state.resourceCodes = [...resourceCodes]?.sort((a, b) => a?.ResourceID! < b?.ResourceID! ? -1 : 1)

      const resourceCodesByResourceType = toMultiMap(state.resourceCodes, rc => rc?.ResourceType ?? "");

      state.resourceCodesByResourceType = resourceCodesByResourceType;

      const resourceIdsByIndex: any = {};

      Object.keys(resourceCodesByResourceType).forEach(key => {
        resourceIdsByIndex[key] = toMap(resourceCodesByResourceType[key], item => item?.ResourceID ?? "", (_, index) => index);
      });

      state.resourceIdIndexesByResourceType = resourceIdsByIndex;

      pavingCrewData?.forEach(pv => {
        if (pv != null && pv?.rtsSupport == null) pv.rtsSupport = "";
        state.schedule.locations[(pv?.locationIndex ?? 1)]?.daysByUnix[pv?.date ?? 0]?.paving.pavingResources.push(pv)
      })

      truckingCrewData?.forEach(tr => {
        state.schedule.locations[(tr?.locationIndex ?? 1)]?.daysByUnix[tr?.date ?? 0]?.trucking.truckingResources.push(tr)
      })

      state.materialDatas = [...materialDatas]?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

      state.lockedDays = lockedDays;

      state.modifiedDayByLocation = {};
      state.modifiedLocation = [];
      state.modifiedLocationInfo = {};

    },
    initPavingModule: (state, action: PayloadAction<any>) => {
      const nrOfDays = 180;

      if (Object.keys(state.pavingModule).length === 0) {
        state.pavingModule = createPavingModule(nrOfDays);
      }

      // const _nrOfWeekes = Math.round(nrOfDays / 7);
      // for (let i = 1; i <= _nrOfWeekes; i++) {
      //   state.pavingModulePriorityJobsList[i] = [];
      // }
    },
    // addDataToPriorityJobsList: (state, action: PayloadAction<InitPavingModule>) => {
    //   const { superIntendentDatas } = action.payload;
    //   const _days = Object.keys(state.pavingModule);
    //   let values: any[] = [];

    //   for (let dayNr = 0; dayNr <= _days.length; dayNr++) {
    //     const _firstDay = _days[dayNr];
    //     const _lastDay = _days[dayNr + 6];
    //     const _weekNr = dayNr === 0 ? 1 : (dayNr / 7 + 1);

    //     const filteredDaysByWeek = superIntendentDatas.filter(d => d?.date >= parseInt(_firstDay) && d?.date <= parseInt(_lastDay));

    //     const jobNumbersFromCurrentWeek = filteredDaysByWeek.map(i => i?.jobNumber);
    //     const _getUnicJobNumbers = jobNumbersFromCurrentWeek.filter((v, i, s) => s.indexOf(v) === i);

    //     _getUnicJobNumbers?.forEach(jn => values.push({ jobNumber: jn, priority: jobNumbersFromCurrentWeek?.filter(j => j === jn).length }))

    //     // values = values.sort((a, b) => {
    //     //   // sort first by priority
    //     //   if (a?.priority! > b?.priority!) return a?.priority! - b?.priority!;

    //     //   return 1;
    //     // });

    //     values = values.sort((n, p) => n.priority! > p.priority! ? -1 : 1);

    //     state.pavingModulePriorityJobsList[_weekNr] = values;

    //     values = [];
    //     dayNr = dayNr + 6;
    //   }
    // },
    addDataToPavingModule: (state, action: PayloadAction<InitPavingModule>) => {
      const { superIntendentDatas } = action.payload;

      if (superIntendentDatas == null) return;

      Object.keys(state.pavingModule).forEach((d, index) => {
        const key = parseInt(d);
        const superIntendentDatasFilteredByDay = superIntendentDatas
          .filter(day => day?.date === parseInt(d));

        // const priorityList = state.pavingModulePriorityJobsList[Math.floor(index / 7) + 1];

        // let sortedItems: Maybe<SuperIntendentPavingCalendarDatas>[] = [];

        // priorityList.forEach((p, i) => {
        //   let _items: Maybe<SuperIntendentPavingCalendarDatas>[] = superIntendentDatasFilteredByDay?.filter(d => d?.jobNumber === p.jobNumber);

        //   if (_items.length > 0) {
        //     sortedItems = [...sortedItems, ..._items];
        //   }
        // });

        const superIntendentDatasOrderByJNAndShift = superIntendentDatasFilteredByDay.sort((a, b) => {
          // sort first by job number
          if (a?.jobNumber! < b?.jobNumber!) return a?.jobNumber! - b?.jobNumber!;

          // if the job numbers are the same, sort by the shift (first day, second the night)
          if (a?.jobNumber! === b?.jobNumber! && a?.shift! < b?.shift!) return (a?.shift! < b?.shift!) ? -1 : 1;

          return 1;
        });

        state.pavingModule[key].items = superIntendentDatasOrderByJNAndShift;
      });
    },

    removeItemFromPavingModule: (state, action: PayloadAction<SuperIntendentPavingCalendarDatas>) => {
      const superIntendentDatas = action.payload;

      if (superIntendentDatas == null) return;
      Object.keys(state.pavingModule).forEach(d => {

        if (parseInt(d) === superIntendentDatas.date) {
          const key = parseInt(d);
          state.pavingModule[key].items.forEach((item, index) => {
            if (item != null && item?.jobNumber === superIntendentDatas?.jobNumber && item?.locationIndex === superIntendentDatas?.locationIndex) {
              delete state.pavingModule[key].items[index];
              let equipsWithAnotherJN = state.pavingModule[key].equipment.filter(eq => eq?.jobNumber !== superIntendentDatas?.jobNumber);
              let schedulesWithAnotherShift = state.pavingModule[key].items.filter(i => i?.jobNumber === superIntendentDatas?.jobNumber && i?.locationIndex !== superIntendentDatas?.locationIndex);
              if (schedulesWithAnotherShift.length === 0) {
                state.pavingModule[key].equipment = [];
                equipsWithAnotherJN.forEach(e => {
                  if (!state.pavingModule[key].equipment.includes(e))
                    state.pavingModule[key].equipment.push(e);
                })
              }
            }
          }
          )
        }
      });
    },

    addIncompleteItemsToPavingModule: (state, action: PayloadAction<AddIncompleteSuperCalendarPavingDatas>) => {
      const { incompleteItems } = action.payload;

      incompleteItems.forEach(item => {
        if (item != null) {
          state.pavingModuleIncompleteItems.push(item)
        }
      })
    },

    addEquipmentSubcategories: (state, action: PayloadAction<AddEquipmentSubcategories>) => {
      const { equipmentsForCheckbox, equipmentsForInput } = action.payload;

      state.equipmentSubcategories!.equipmentsForCheckbox = equipmentsForCheckbox;
      state.equipmentSubcategories!.equipmentsForInput = equipmentsForInput;
    },

    addEquipmentResources: (state, action: PayloadAction<AddEquipmentResources>) => {
      state.equipmentResources = action.payload.equipmentResources;
    },

    addDayStatusToPavingModule: (state, action: PayloadAction<AddDayStatusForPavingModule>) => {
      const { superIntendentPavingCalendarItems } = action.payload;
      const firstDayAllowedToModify = moment().utc();

      Object.keys(state.pavingModule).forEach(d => {
        const currentDate = parseInt(d);

        if (state.pavingModuleUser.isInPavingSupers === true && state.pavingModuleUser.isInGeneralPavingSupers === false) {
          state.pavingModule[currentDate].isDayBlocked = true;
        }
        else {
          const allowToModify =
            moment(currentDate).utc().format("YYYY.MM.DD") <
              firstDayAllowedToModify.format("YYYY.MM.DD")
              ? false
              : true;

          state.pavingModule[currentDate].isDayBlocked = !allowToModify;

          superIntendentPavingCalendarItems != null && superIntendentPavingCalendarItems.forEach(item => {
            if (item?.date === currentDate && item?.status === true) {
              state.pavingModule[currentDate].isDayBlocked = item?.status as boolean;
            }
          })
        }
      })
    },

    addBlockedJobNumbersAndForemansToPavingModule: (state, action: PayloadAction<AddBlockedJobNumbersToPavingModule>) => {
      const { pavingCalendarItems } = action.payload;

      if (pavingCalendarItems == null) return;

      Object.keys(state.pavingModule).forEach(d => {
        const key = parseInt(d);
        const superIntendentDatasFilteredByDay = pavingCalendarItems.filter(item => item?.date === parseInt(d));
        if (superIntendentDatasFilteredByDay?.length > 0) {
          state.pavingModule[key].jobNumbersBlocked = superIntendentDatasFilteredByDay?.[0]?.jobNumbersBlocked as number[];
          state.pavingModule[key].noWorkForForeman = superIntendentDatasFilteredByDay?.[0]?.noWorkForForemans as string[];
        } else {
          state.pavingModule[key].jobNumbersBlocked = [];
          state.pavingModule[key].noWorkForForeman = [];
        }
      })
    },
    movePavingItemToAnotherDate: (state, action: PayloadAction<ChangePavingItemToAnotherDay>) => {
      const { date, newDate, jobNumber, locationIndex, shift } = action.payload;
      const itemsWithSameJN = state.pavingModule[newDate].items.filter(item => item?.jobNumber === jobNumber);
      // verificare daca pe new date avem item cu jobN === jobNumber && shift 
      // daca au acelasi jn si shift sau jn === jn && shift !=

      const numberOfItemsOnDate = state.pavingModule[date].items.length;
      const numberOfItemsOnNewDate = state.pavingModule[newDate].items.length;

      for (let indexDate = 0; indexDate < numberOfItemsOnDate; indexDate++) {
        if (state.pavingModule[date].items[indexDate]?.jobNumber as number === jobNumber &&
          state.pavingModule[date].items[indexDate]?.shift as string === shift) {
          let itemToMove = state.pavingModule[date].items[indexDate];
          itemToMove!.date = newDate;
          itemToMove!.locationIndex = locationIndex;

          let foundItemWithSameJN = false;

          for (let indexNewDate = 0; indexNewDate < numberOfItemsOnNewDate; indexNewDate++) {
            if (state.pavingModule[newDate].items[indexNewDate]?.jobNumber as number === jobNumber) {
              const itemToBeOverwritten: SuperIntendentPavingCalendarItemInput = {
                bookTruckVendor: state.pavingModule[newDate].items[indexNewDate]?.bookTruckVendor,
                broker: state.pavingModule[newDate].items[indexNewDate]?.broker,
                crewMakeLab: state.pavingModule[newDate].items[indexNewDate]?.crewMakeLab,
                crewMakeOp: state.pavingModule[newDate].items[indexNewDate]?.crewMakeOp,
                date: state.pavingModule[newDate].items[indexNewDate]?.date,
                extraWork: state.pavingModule[newDate].items[indexNewDate]?.extraWork,
                grinder12ft: state.pavingModule[newDate].items[indexNewDate]?.grinder12ft,
                grinder4ft: state.pavingModule[newDate].items[indexNewDate]?.grinder4ft,
                grinder6ft: state.pavingModule[newDate].items[indexNewDate]?.grinder6ft,
                grinder7ft: state.pavingModule[newDate].items[indexNewDate]?.grinder7ft,
                jobName: state.pavingModule[newDate].items[indexNewDate]?.jobName,
                jobNumber: state.pavingModule[newDate].items[indexNewDate]?.jobNumber,
                jobMap: state.pavingModule[newDate].items[indexNewDate]?.jobMap,
                locationIndex: state.pavingModule[newDate].items[indexNewDate]?.locationIndex,
                material: state.pavingModule[newDate].items[indexNewDate]?.material,
                mixDesignApproval: state.pavingModule[newDate].items[indexNewDate]?.mixDesignApproval,
                mixSubmital: state.pavingModule[newDate].items[indexNewDate]?.mixSubmital,
                nrOfTrucks: state.pavingModule[newDate].items[indexNewDate]?.nrOfTrucks,
                pavingForeman: state.pavingModule[newDate].items[indexNewDate]?.pavingForeman,
                pavingSuperIntendent: state.pavingModule[newDate].items[indexNewDate]?.pavingSuperIntendent,
                plant: state.pavingModule[newDate].items[indexNewDate]?.plant,
                pmpe: state.pavingModule[newDate].items[indexNewDate]?.pmpe,
                rtsSupport: state.pavingModule[newDate].items[indexNewDate]?.rtsSupport,
                shift: state.pavingModule[newDate].items[indexNewDate]?.shift,
                superIntendent: state.pavingModule[newDate].items[indexNewDate]?.superIntendent,
                tonnage: state.pavingModule[newDate].items[indexNewDate]?.tonnage,
                tph: state.pavingModule[newDate].items[indexNewDate]?.tph,
                typeOfTrucks: state.pavingModule[newDate].items[indexNewDate]?.typeOfTrucks,
                uts: state.pavingModule[newDate].items[indexNewDate]?.uts
              }

              if (itemsWithSameJN.length === 1 && foundItemWithSameJN === false) {
                if (state.pavingModule[newDate].items[indexNewDate]?.shift as string === shift) {
                  // overwrite item from new date with item from date
                  //push to "recycle"
                  state.pavingModule[newDate].itemsToRemove.push(itemToBeOverwritten);
                  state.pavingModule[newDate].items.splice(indexNewDate, 1);
                  state.pavingModule[newDate].items.push(itemToMove);
                }
                if (state.pavingModule[newDate].items[indexNewDate]?.shift as string !== shift) {
                  state.pavingModule[newDate].items.push(itemToMove);
                }
                foundItemWithSameJN = true;
              }
              if (itemsWithSameJN.length === 2) {
                if (state.pavingModule[newDate].items[indexNewDate]?.shift as string === shift && foundItemWithSameJN === false) {
                  //push to "recycle"
                  state.pavingModule[newDate].itemsToRemove.push(itemToBeOverwritten);
                  state.pavingModule[newDate].items.splice(indexNewDate, 1);
                  state.pavingModule[newDate].items.push(itemToMove);
                  foundItemWithSameJN = true;
                }
              }
            }
          }
          // in the new date, we don't have items with the same job number
          if (itemsWithSameJN.length === 0) {
            state.pavingModule[newDate].items.push(itemToMove);
          }
          state.pavingModule[date].items.splice(state.pavingModule[date].items.indexOf(itemToMove), 1);
        }
      }
    },
    updateBlockedForemanFromDay: (state, action: PayloadAction<UpdateBlockedForemanFromDay>) => {
      const { unix, foremans } = action.payload;

      state.pavingModule[unix].noWorkForForeman = [];

      if (foremans?.length > 0) {
        foremans?.map(f => {
          if (!state.pavingModule[f.unix].noWorkForForeman?.includes(f.foremanName) && f.lock) {
            state.pavingModule[f.unix].noWorkForForeman?.push(f.foremanName);
          } else if (state.pavingModule[f.unix].noWorkForForeman?.includes(f.foremanName) && !f.lock) {
            let foremanIndex = state.pavingModule[f.unix].noWorkForForeman?.indexOf(f.foremanName);
            state.pavingModule[f.unix].noWorkForForeman?.splice(foremanIndex, 1);
          }
        })
      }
    },
    updateJobNumberStatusFromDay: (state, action: PayloadAction<UpdateJobNumberStatusFromDay>) => {
      const { jobNumber, unix, lock } = action.payload;

      if (!state.pavingModule[unix].jobNumbersBlocked?.includes(jobNumber) && lock) {
        state.pavingModule[unix].jobNumbersBlocked?.push(jobNumber);
      } else if (state.pavingModule[unix].jobNumbersBlocked?.includes(jobNumber) && !lock) {
        let jobNumberIndex = state.pavingModule[unix].jobNumbersBlocked?.indexOf(jobNumber);
        state.pavingModule[unix].jobNumbersBlocked?.splice(jobNumberIndex, 1);
      }
    },
    updateJobsNumbersRedBordered: (state, action: PayloadAction<UpdateJobsNumbersRedBordered>) => {
      const { jobNumber, unix, status } = action.payload;

      if (!state.pavingModule[unix].jobNumbersRedBordered?.includes(jobNumber) && status) {
        state.pavingModule[unix].jobNumbersRedBordered?.push(jobNumber);
      } else if (state.pavingModule[unix].jobNumbersRedBordered?.includes(jobNumber) && !status) {
        let jobNumberIndex = state.pavingModule[unix].jobNumbersRedBordered?.indexOf(jobNumber);
        state.pavingModule[unix].jobNumbersRedBordered?.splice(jobNumberIndex, 1);
      }
    },
    updateDayStatusForPavingModule: (state, action: PayloadAction<UpdateDayStatusForPavingModule>) => {
      const { isDayBlocked, unix } = action.payload;

      if (state.pavingModule[unix] != null) {
        state.pavingModule[unix].isDayBlocked = isDayBlocked;
      }
    },
    changePavingItemForeman: (state, action: PayloadAction<ChangePavingItemForeman>) => {
      const { date, jobNumber, locationIndex, pavingForeman, shift } = action.payload;

      if (state.pavingModule[date] != null) {
        state.pavingModule[date].items.forEach(item => {
          if (item?.jobNumber === jobNumber && item.locationIndex === locationIndex && item?.shift === shift) {
            item.pavingForeman = pavingForeman;
          }
        })
      }
    },
    addCustomCrews: (state, action: PayloadAction<CustomCrewsPayload>) => {
      const { customCrews } = action.payload;
      state.customCrews = [...customCrews].sort((a, b) => a?.crewDescription! < b?.crewDescription! ? -1 : 1);
    },
    addJobNumberInformation: (state, action: PayloadAction<JobNumberInformationPayload>) => {
      state.jobNumberInformations = action.payload.jobNumberInformations;
    },
    addLocation: (state, action: PayloadAction<string>) => {
      const locations = Object.keys(state.schedule.locations);
      // maximum locations numbers
      state.schedule.locations[locations.length + 1] = createLocation(state.start, action.payload, "");
    },
    changeDoubledShift: (state, action: PayloadAction<ChangeDoubledShiftPlayload>) => {
      const { stateName, day, locationIndex } = action.payload;
      if (stateName === "stateCopyDay") {
        if (state.copyDay?.paving?.pavingResources?.[0]?.shift === "D") {
          state?.copyDay?.paving?.pavingResources?.forEach(pav => {
            if (pav != null)
              pav.shift = "N";
          });
          state?.copyDay?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "N";
          });
        }
        else if (state.copyDay?.paving?.pavingResources?.[0]?.shift === "N") {
          state?.copyDay?.paving?.pavingResources?.forEach(pav => {
            if (pav != null)
              pav.shift = "D";
          });
          state?.copyDay?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "D";
          });
        }
        else if (state.copyDay?.paving?.pavingResources?.[0]?.shift === "D 2") {
          state?.copyDay?.paving?.pavingResources?.forEach(pav => {
            if (pav != null)
              pav.shift = "N 2";
          });
          state?.copyDay?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "N 2";
          });
        }
        else if (state.copyDay?.paving?.pavingResources?.[0]?.shift === "N 2") {
          state?.copyDay?.paving?.pavingResources?.forEach(pav => {
            if (pav != null)
              pav.shift = "D 2";
          });
          state?.copyDay?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "D 2";
          });
        }
      }
      //change trk shift too
      if (stateName === "stateCopyPaving") {
        if (state.copyPaving?.[0]?.shift === "D") {
          state.copyPaving?.forEach(pav => {
            if (pav != null)
              pav.shift = "N";
          });
          state?.schedule?.locations?.[locationIndex]?.daysByUnix?.[day]?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "N";
          })
        }
        else if (state.copyPaving?.[0]?.shift === "N") {
          state.copyPaving?.forEach(pav => {
            if (pav != null)
              pav.shift = "D";
          });
          state?.schedule?.locations?.[locationIndex]?.daysByUnix?.[day]?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "D";
          })
        }
        else if (state.copyPaving?.[0]?.shift === "D 2") {
          state.copyPaving?.forEach(pav => {
            if (pav != null)
              pav.shift = "N 2";
          });
          state?.schedule?.locations?.[locationIndex]?.daysByUnix?.[day]?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "N 2";
          })
        }
        else if (state.copyPaving?.[0]?.shift === "N 2") {
          state.copyPaving?.forEach(pav => {
            if (pav != null)
              pav.shift = "D 2";
          });
          state?.schedule?.locations?.[locationIndex]?.daysByUnix?.[day]?.trucking?.truckingResources?.forEach(trk => {
            if (trk != null)
              trk.shift = "D 2";
          })
        }
      }
    },
    clearDay: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix, lockedDays } = action.payload;
      const md = moment.utc(unix);
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
        state.lastDay = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix];
        if (lockedDays?.includes(unix)) {
          state.schedule.locations[locationIndex].daysByUnix[unix] = clearDayForLockedDays(state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix], md);
        } else {
          state.schedule.locations[locationIndex].daysByUnix[unix] = createDay(md);
        }

        state.schedule.locations[locationIndex].daysByUnix[unix].forecast = state.lastDay.forecast;

        if (state.modifiedDayByLocation[locationIndex] == null) {
          state.modifiedDayByLocation[locationIndex] = {}
        }

        if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
          state.modifiedDayByLocation[locationIndex][unix] = ["clearDay"];
        }

        if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("clearDay")) {
          state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("clearDay");
        }
      }
    },
    pasteDay: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix, lockedDays } = action.payload;
      const md = moment.utc(unix);

      if (state.copyDay == null) {
        /// paste trucking resource
        if (state.copyTrucking != null) {
          let copyTrucking = state.copyTrucking;

          copyTrucking = state.copyTrucking.filter(tr => tr?.operationType !== "paving");
          let pavingTrkResources = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.filter(tr => tr?.operationType === "paving");

          state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = pavingTrkResources;

          copyTrucking.forEach(tr => {
            state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
          })

          const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix];

          const truckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK");

          const qtySumFromTruckingResourcesFromDay = day.trucking.truckingResources
            ?.map(tr => tr?.qty)
            ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

          if (truckingJobInfos.length > 0) {
            if (truckingJobInfos[0] == null) { return }
            truckingJobInfos[0].resourceQTY = qtySumFromTruckingResourcesFromDay
            return;
          }

          const jobInfos = day.equip.jobInfos;
          // we loop the for until 10, because we have only 10 dropdowns for every category: labor or equipment
          // we must check if all 10 dropdowns are filled before the user paste the resources
          for (let i = 0; i < 10; i++) {
            if (jobInfos[i] != null) { continue; }
            if (copyTrucking.length > 0) {
              jobInfos[i] = {
                pmpe: state.jobNumberInformations?.projectManager,
                jobName: state.jobNumberInformations?.jobName,
                date: unix,
                foreman: " ",
                description: " ",
                resourceID: "TRK",
                resourceType: "Equipment",
                additionalResourcesComments: " ",
                searchGSI_JobNumber: state.start.jobNumber as number,
                locationIndex: locationIndex,
                areaManager: state.jobNumberInformations?.areaManager,
                resourceName: "Trucking",
                resourceQTY: qtySumFromTruckingResourcesFromDay
              } as JobInfo
            }
            break;
          }
          if (copyTrucking.length > 0) {
            state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["TRK"] = true;
          }

          if (state.modifiedDayByLocation[locationIndex] == null) {
            state.modifiedDayByLocation[locationIndex] = {}
          }

          if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
            state.modifiedDayByLocation[locationIndex][unix] = ["true"];
            if (copyTrucking.length > 0) {
              state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
            }
          }

          if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
          }

          if (state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.length > 0) {
            if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
              state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
            }
          }

          if (state.modifiedDayByLocation[locationIndex][unix]?.includes("clearDay")) {
            const index = state.modifiedDayByLocation[locationIndex][unix].indexOf("clearDay")
            if (index !== -1) {
              state.modifiedDayByLocation[locationIndex][unix].splice(index, 1)
            }
          }
          /// end paste trucking
        }
        // start paste paving
        if (state.copyPaving != null && state.copyPavingJobInfo != null) {

          let copyPaving: Maybe<MasterCrewSchedulePaving>[] | null = state.copyPaving;

          // we cannot add paving on a locked day or if the location index is higher than 4
          if (lockedDays?.includes(unix) || locationIndex > 4) {
            copyPaving = null;
          }

          if (copyPaving != null) {
            const _pavingRes = state.copyPaving;
            _pavingRes.forEach(pav => {
              pav!.addedDate = moment().utc().format("YYYY-MM-DD");
              pav!.timeStamp = moment().utc().format();
            })
            state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = _pavingRes;

            let nonPavingTrkResources = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.filter(tr => tr?.operationType !== "paving");

            state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = nonPavingTrkResources;

            if (state.copyPavingTruckingResources != null && state.copyPavingTruckingResources.length > 0) {
              state.copyPavingTruckingResources.forEach(tr => {
                // change addedDate
                state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
              })
            }

            const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix];
            const pavingJobInfos = day.labor.jobInfos.filter(ji => ji?.resourceID === "PAV");
            // if (pavingJobInfos.length > 0) {
            //   if (pavingJobInfos[0] == null) { return }
            //   pavingJobInfos[0].resourceQTY = state.copyPavingJobInfo?.resourceQTY;
            // }
            if (pavingJobInfos.length === 0) {
              const jobInfosLabor = day.labor.jobInfos;
              for (let i = 0; i < 10; i++) {
                if (jobInfosLabor[i] != null) { continue; }
                jobInfosLabor[i] = {
                  pmpe: state.jobNumberInformations?.projectManager,
                  jobName: state.jobNumberInformations?.jobName,
                  date: unix,
                  foreman: " ",
                  description: " ",
                  resourceID: "PAV",
                  resourceType: "Labor",
                  additionalResourcesComments: " ",
                  searchGSI_JobNumber: state.start.jobNumber as number,
                  locationIndex: locationIndex,
                  areaManager: state.jobNumberInformations?.areaManager,
                  resourceName: "Paving",
                  resourceQTY: state.copyPavingJobInfo?.resourceQTY,
                } as JobInfo
                break;
              }
              state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["PAV"] = true;
            }

            if (state.copyPavingTruckingResources?.length !== 0) {
              const pavingTruckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK");

              // for trucking resource from JobInfo table, we sum all the qty'es from resources inside
              const qtySumFromTruckingResourcesFromDay = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources
                ?.map(tr => tr?.qty)
                ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

              if (pavingTruckingJobInfos.length > 0) {
                if (pavingTruckingJobInfos[0] == null) { return }
                pavingTruckingJobInfos[0].resourceQTY = qtySumFromTruckingResourcesFromDay;
              }
              else {
                const jobInfosEquip = day.equip.jobInfos;
                for (let i = 0; i < 10; i++) {
                  if (jobInfosEquip[i] != null) { continue; }
                  jobInfosEquip[i] = {
                    pmpe: state.jobNumberInformations?.projectManager,
                    jobName: state.jobNumberInformations?.jobName,
                    date: unix,
                    foreman: " ",
                    description: " ",
                    resourceID: "TRK",
                    resourceType: "Equipment",
                    additionalResourcesComments: " ",
                    searchGSI_JobNumber: state.start.jobNumber as number,
                    locationIndex: locationIndex,
                    areaManager: state.jobNumberInformations?.areaManager,
                    resourceName: "Trucking",
                    resourceQTY: qtySumFromTruckingResourcesFromDay,
                  } as JobInfo
                  break;
                }
                state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["TRK"] = true;
              }
            }

            if (state.modifiedDayByLocation[locationIndex] == null) {
              state.modifiedDayByLocation[locationIndex] = {}
            }

            if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
              state.modifiedDayByLocation[locationIndex][unix] = ["true"];
              if (state.copyPaving?.length > 0) {
                state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("paving");
              }

              if (state.copyPavingTruckingResources != null && state.copyPavingTruckingResources.length > 0) {
                state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
              }
            }

            if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
              state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
            }

            if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.length > 0) {
              if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
                state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
              }
            }

            if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving?.pavingResources?.length > 0) {
              if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("paving")) {
                state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("paving");
              }
            }

            if (state.modifiedDayByLocation[locationIndex][unix]?.includes("clearDay")) {
              const index = state.modifiedDayByLocation[locationIndex][unix].indexOf("clearDay")
              if (index !== -1) {
                state.modifiedDayByLocation[locationIndex][unix].splice(index, 1)
              }
            }
            // end paste paving
          }
        }
        return
      }

      // copy day has initial day state, after that depending if the day is locker or not we populate that variable
      // it's changing the object that is gonna be pasted, if the day is locked, we remove all the paving elements
      let copyDay = state.copyDay;
      const trkResFromLongRange = state.copyDay.trucking.truckingResources.filter(tr => tr?.updated === false);
      const pavResFromLongRange = state.copyDay.paving.pavingResources.filter(pav => pav?.updated === false);

      // if the day is locked and there are already on that day paving resources, we keep them
      if (lockedDays?.includes(unix) || locationIndex > 4 || (trkResFromLongRange.length > 0 || pavResFromLongRange.length > 0)) {

        const dayResourcesBeforePaste = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix];

        // resources from copy day
        const truckingResourcesWithoutOpTypePaving = state.copyDay.trucking.truckingResources?.filter(tr => tr?.operationType !== "paving");
        const equipJobInfosWithoutTrk = state.copyDay?.equip.jobInfos.filter(ji => ji?.resourceID !== "TRK");
        const equipJobInfosTrk = state.copyDay?.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")[0];
        const laborJobInfosWithoutPav = state.copyDay?.labor.jobInfos.filter(ji => ji?.resourceID !== "PAV");

        // resources from current day before pasting
        const truckingResourcesCurrentDayWithOpTypePav = dayResourcesBeforePaste.trucking.truckingResources.filter(tr => tr?.operationType === "paving");
        const pavingJobInfoCurrentDay = dayResourcesBeforePaste.labor.jobInfos.filter(ji => ji?.resourceID === "PAV")[0];
        const trkJobInfoCurrentDay = dayResourcesBeforePaste.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")[0];

        state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos = [];

        if (pavingJobInfoCurrentDay != null) {
          state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos.push(pavingJobInfoCurrentDay);
        }

        laborJobInfosWithoutPav.forEach(l => {
          state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos.push(l);
        })

        state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos = [];
        state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];

        if (truckingResourcesCurrentDayWithOpTypePav.length > 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.push(trkJobInfoCurrentDay);

          truckingResourcesCurrentDayWithOpTypePav.forEach(tr => {
            state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
          })
        } else {
          if (truckingResourcesWithoutOpTypePaving.length > 0) {
            state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.push(equipJobInfosTrk);
          }
        }

        equipJobInfosWithoutTrk.forEach(e => {
          state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.push(e);
        })

        truckingResourcesWithoutOpTypePaving.forEach(tr => {
          state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
        })

        const qtySumFromTruckingResourcesFromDay = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources
          ?.map(tr => tr?.qty)
          ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

        state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.equip?.jobInfos?.forEach(ji => {
          if (ji?.resourceID === "TRK") {
            ji.resourceQTY = qtySumFromTruckingResourcesFromDay
          }
        })

        if (state.modifiedDayByLocation[locationIndex] == null) {
          state.modifiedDayByLocation[locationIndex] = {}
        }

        if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
          state.modifiedDayByLocation[locationIndex][unix] = ["true"];
        }

        if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
          state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
        }
      }
      //day is not locked or locIndex < 4
      else {
        if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
          copyDay.date = md.format("MM/DD/YYYY");
          copyDay.unix = md.valueOf();
          copyDay.day = md.weekday();
          copyDay.weekDay = md.format("ddd")
          state.lastDay = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix];
          let forecast = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.forecast;
          state.schedule.locations[locationIndex].daysByUnix[unix] = copyDay
          state.schedule.locations[locationIndex].daysByUnix[unix].forecast = forecast;
        }

        const qtySumFromTruckingResourcesFromDay = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources
          ?.map(tr => tr?.qty)
          ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

        state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.forEach(ji => {
          if (ji?.resourceID === "TRK") {
            ji.resourceQTY = qtySumFromTruckingResourcesFromDay
          }
        })

        if (state.modifiedDayByLocation[locationIndex] == null) {
          state.modifiedDayByLocation[locationIndex] = {}
        }

        if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
          state.modifiedDayByLocation[locationIndex][unix] = ["true"];
          if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.length > 0) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
          }
          if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving?.pavingResources?.length > 0) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("paving");
          }
        }

        if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.length > 0) {
          if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
          }
        }

        if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving?.pavingResources?.length > 0) {
          if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("paving")) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("paving");
          }
        }

        if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
          if (state.modifiedDayByLocation[locationIndex][unix]?.includes("clearDay")) {
            const index = state.modifiedDayByLocation[locationIndex][unix].indexOf("clearDay")
            if (index !== -1) {
              state.modifiedDayByLocation[locationIndex][unix].splice(index, 1)
            }
          }

          if (!state.modifiedDayByLocation?.[locationIndex]?.[unix]?.includes("true")) {
            state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
          }

          if (!state.modifiedDayByLocation?.[locationIndex]?.[unix]?.includes("trucking")) {
            if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources?.length > 0) {
              state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("trucking");
            }
          }

          if (!state.modifiedDayByLocation?.[locationIndex]?.[unix]?.includes("paving")) {
            if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving?.pavingResources?.length > 0) {
              state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("paving");
            }
          }
        }
      }
    },
    undoDay: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix } = action.payload;
      if (state.lastDay == null) { return }
      if (state.lastDay.unix !== unix) { return }
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] !== null) {
        state.schedule.locations[locationIndex].daysByUnix[unix] = state.lastDay
      }
    },
    copyDay: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix } = action.payload;
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
        state.copyDay = state.schedule.locations[locationIndex].daysByUnix[unix];
        state.copyDay.locationIndex = locationIndex;
        state.copyTruckingJobInfo = null;
        state.copyPaving = null;
      }
    },
    copyTrucking: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix } = action.payload;
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix].trucking.truckingResources != null) {
        state.copyTrucking = state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources;
        state.copyTruckingJobInfo = state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.filter(ji => ji?.resourceID === "TRK")[0] as JobInfo;
        state.copyDay = null;
        state.copyPaving = null;
      }
    },
    copyPaving: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix } = action.payload;
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix].paving.pavingResources != null) {
        state.copyPaving = state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources;
        state.copyPavingJobInfo = state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos.filter(ji => ji?.resourceID === "PAV")[0] as JobInfo;
        state.copyPavingTruckingResourceJobInfo = state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.filter(ji => ji?.resourceID === "TRK")[0] as JobInfo;
        state.copyPavingTruckingResources = state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources.filter(tr => tr?.operationType === "paving");
        state.copyDay = null;
        state.copyTrucking = null;
      }
    },
    copyResourcesWithoutPav: (state, action: PayloadAction<ClearDayPayload>) => {
      const { locationIndex, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
        const _laborWithoutPav = state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos.filter((ji) => ji?.resourceID !== "PAV");
        const _trkResourcesWithoutPav = state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources.filter(tr => tr?.operationType !== "paving");
        const qtyTrkResourcesWithoutPav = _trkResourcesWithoutPav
          .map(tr => tr?.qty as number)
          .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

        let _trkNonPav = state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.filter((ji) => ji?.resourceID !== "TRK")?.[0];
        _trkNonPav = _trkNonPav != null ? {
          ..._trkNonPav,
          resourceQTY: (_trkResourcesWithoutPav != null && _trkResourcesWithoutPav?.length === 0) ? 0 : qtyTrkResourcesWithoutPav as number
        }
          : null;

        const equip = state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.filter((ji) => ji?.resourceID !== "TRK");

        const _allResources = [..._laborWithoutPav, ...equip, _trkNonPav];
        const _selectedResources: { [resourceID: string]: boolean } = {};

        _allResources?.forEach((r) => {
          if (r != null) {
            _selectedResources[r?.resourceID as string] = true;
          }
        })

        const _day: DayState = {
          ...state.schedule.locations[locationIndex].daysByUnix[unix],
          paving: {
            pavingResources: []
          },
          labor: {
            jobInfos: _laborWithoutPav
          },
          trucking: {
            truckingResources: _trkResourcesWithoutPav
          },
          equip: {
            jobInfos: [...equip, _trkNonPav]
          },
          selectedResources: _selectedResources
        };

        state.copyDay = _day;
        state.copyDay.locationIndex = locationIndex;
        state.copyTruckingJobInfo = null;
        state.copyPaving = null;
      }
    },
    JobChange: (state, action: PayloadAction<JobChangePayload>) => {
      const { locationIndex, unix, indexPos, rc } = action.payload;
      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {

        if (rc?.ResourceType === "Labor") {
          state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos[indexPos!] = {
            pmpe: state.jobNumberInformations?.projectManager,
            jobName: state.jobNumberInformations?.jobName,
            date: unix,
            additionalResourcesComments: " ",
            foreman: " ",
            description: " ",
            resourceID: rc?.ResourceID,
            superIntendent: state.jobNumberInformations?.superIntendentName?.length === 0 ? " " : state.jobNumberInformations?.superIntendentName,
            resourceType: rc?.ResourceType,
            searchGSI_JobNumber: state.start.jobNumber as number,
            locationIndex: locationIndex,
            areaManager: state.jobNumberInformations?.areaManager,
            resourceName: rc?.ResourceDescription,
            pavingForeman: " ",
            pavingSuperIntendent: " ",
          }
        }

        if (rc?.ResourceType === "Equipment") {
          state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos[indexPos!] = {
            pmpe: state.jobNumberInformations?.projectManager,
            jobName: state.jobNumberInformations?.jobName,
            date: unix,
            foreman: " ",
            description: " ",
            resourceID: rc?.ResourceID,
            superIntendent: state.jobNumberInformations?.superIntendentName?.length === 0 ? " " : state.jobNumberInformations?.superIntendentName,
            resourceType: rc?.ResourceType,
            additionalResourcesComments: " ",
            searchGSI_JobNumber: state.start.jobNumber as number,
            locationIndex: locationIndex,
            areaManager: state.jobNumberInformations?.areaManager,
            resourceName: rc?.ResourceDescription,
            pavingForeman: " ",
            pavingSuperIntendent: " ",
          }
        }

        state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources[rc?.ResourceID as string] = true;

        if (state.modifiedDayByLocation[locationIndex] == null) {
          state.modifiedDayByLocation[locationIndex] = {}
        }

        if (state.modifiedDayByLocation[locationIndex][unix].includes("true")) {
          return;
        }

        if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0) {
          state.modifiedDayByLocation[locationIndex][unix] = ["true"];
        }

        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }
    },
    RemoveJob: (state, action: PayloadAction<RemoveJobPayload>) => {
      const { locationIndex, indexPos, unix, resourceType, resourceID } = action.payload;
      const rt = resourceType === "equipment" ? "equip" : "labor";
      delete state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.[rt]?.jobInfos?.[indexPos!];
      delete state.schedule.locations[locationIndex]?.daysByUnix[unix]?.selectedResources?.[resourceID as string];

      if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
        if (Object.values(state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.[rt]?.jobInfos).length === 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix][rt].jobInfos = [];
        }
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["true"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }
    },
    JobQTY: (state, action: PayloadAction<JobChangePayload>) => {
      const { locationIndex, unix, indexPos, qty, resourceType } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) { return }
      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["true"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }

      if (resourceType === "labor") {
        const job = state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos[indexPos!]
        if (job == null) { return }
        if (qty != null) {
          if (qty === "") {
            job.resourceQTY = null
            return
          }
          job.resourceQTY = qty as number
        }
        return
      }
      const job = state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos[indexPos!]
      if (job == null) { return }
      if (qty != null) {
        job.resourceQTY = qty as number
      }
    },

    addPavingFromPavingManagement: (state, action: PayloadAction<AddPavingFromPavingManagement>) => {
      const { locationIndex, jobInfoResources, jobInfoToUpdate, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex] == null) { return }

      if (jobInfoResources != null && jobInfoResources.length > 0) {
        const _resources: Maybe<JobInfo>[] = [];
        jobInfoResources.forEach(ji => {
          _resources.push(ji);
        })

        const resourcesForTrk = _resources.filter(ji => ji?.resourceID === "TRK");
        const resourcesForPav = _resources.filter(ji => ji?.resourceID === "PAV");
        const resourcesForACP = _resources.filter(ji => ji?.resourceID === "ACP");

        if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) { return }
        if (state.modifiedDayByLocation[locationIndex] == null) {
          state.modifiedDayByLocation[locationIndex] = {}
        }

        if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
          state.modifiedDayByLocation[locationIndex][unix] = ["true"];
        }

        if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
          state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
        }

        if (resourcesForPav.length > 0) {
          resourcesForPav.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
                const _includesPaving = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("PAV");

                if (_includesPaving === false) {
                  state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
        if (resourcesForACP.length > 0) {
          resourcesForACP.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
                const _includesAcp = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("ACP");

                if (_includesAcp === false) {
                  state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
        if (resourcesForTrk.length > 0) {
          resourcesForTrk?.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
                const _includesTrucking = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].equip.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("TRK");

                if (_includesTrucking === false) {
                  state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].equip.jobInfos.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
      }

      if (jobInfoToUpdate != null && jobInfoToUpdate.length > 0) {
        jobInfoToUpdate.forEach(ji => {
          if (ji != null) {
            if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
              const _itemToUpdate = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].equip.jobInfos.filter(ji => ji?.resourceID === "TRK");
              if (_itemToUpdate != null && _itemToUpdate.length > 0) {
                _itemToUpdate[0]!.resourceQTY = ji.resourceQTY
              }
            }
          }
        })
      }
    },

    addPavingFromPavingCalendarResources: (state, action: PayloadAction<AddPavingFromPavingCalendarResources>) => {
      const { locationIndex, pavingResources, jobInfoResources, truckingResources, jobInfoToUpdate } = action.payload;

      if (state.schedule?.locations?.[locationIndex] == null) { return }

      if (jobInfoResources != null && jobInfoResources.length > 0) {
        const _resources: Maybe<JobInfo>[] = [];
        jobInfoResources.forEach(ji => {
          _resources.push(ji);
        })

        const resourcesForTrk = _resources.filter(ji => ji?.resourceID === "TRK");
        const resourcesForPav = _resources.filter(ji => ji?.resourceID === "PAV");
        const resourcesForACP = _resources.filter(ji => ji?.resourceID === "ACP");

        if (resourcesForPav.length > 0) {
          resourcesForPav.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
                const _includesPaving = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("PAV");

                if (_includesPaving === false) {
                  state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].labor.jobInfos.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
        if (resourcesForACP.length > 0) {
          resourcesForACP.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
                const _includesAcp = state.schedule.locations?.[ji.locationIndex!]?.daysByUnix[ji.date]?.labor?.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("ACP");

                if (_includesAcp === false) {
                  state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.labor?.jobInfos?.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
        if (resourcesForTrk.length > 0) {
          resourcesForTrk?.forEach(ji => {
            if (ji != null) {
              if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date] != null) {
                const _includesTrucking = state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.equip?.jobInfos
                  ?.map(j => j?.resourceID)
                  ?.includes("TRK");

                if (_includesTrucking === false) {
                  state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].equip.jobInfos.push(ji);

                  if (state.schedule.locations?.[ji.locationIndex!]?.daysByUnix?.[ji.date]?.selectedResources != null) {
                    state.schedule.locations[(ji?.locationIndex ?? 1)].daysByUnix[ji?.date ?? 0].selectedResources[ji?.resourceID as string] = true;
                  }
                }
              }
            }
          })
        }
      }
      if (pavingResources != null && pavingResources.length > 0) {
        const _resources: Maybe<MasterCrewSchedulePaving>[] = [];
        pavingResources.forEach(pav => {
          _resources.push(pav);
        })

        _resources.forEach(pav => {
          if (pav != null) {
            if (state.schedule.locations?.[pav.locationIndex!]?.daysByUnix?.[pav.date] != null) {
              const _hasPavingResources = state.schedule.locations?.[pav.locationIndex!]?.daysByUnix[pav.date].paving.pavingResources.length > 0;
              if (_hasPavingResources === false) {
                state.schedule.locations?.[pav.locationIndex!]?.daysByUnix?.[pav.date!]?.paving?.pavingResources?.push(pav);
              }
            }
          }
        })
      }

      if (truckingResources != null && truckingResources.length > 0) {
        const _resources: Maybe<MasterCrewSchedule>[] = [];
        truckingResources.forEach(trk => {
          _resources.push(trk);
        })

        _resources.forEach(trk => {
          if (trk != null) {
            if (state.schedule.locations?.[trk.locationIndex!]?.daysByUnix?.[trk.date] != null) {
              const _hasPavingResources = state.schedule.locations?.[trk.locationIndex!]?.daysByUnix?.[trk.date]?.trucking?.truckingResources?.filter(tr => tr?.operationType === "paving").length > 0;

              if (_hasPavingResources === false) {
                state.schedule.locations?.[trk.locationIndex!]?.daysByUnix?.[trk.date!]?.trucking?.truckingResources?.push(trk);
              }
            }
          }
        })
      }

      if (jobInfoToUpdate != null && jobInfoToUpdate.length > 0) {
        jobInfoToUpdate.forEach(ji => {
          if (ji != null) {
            if (state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date] != null) {
              const _itemToUpdate = state.schedule.locations?.[ji.locationIndex!].daysByUnix[ji.date].equip.jobInfos.filter(ji => ji?.resourceID === "TRK");
              if (_itemToUpdate != null && _itemToUpdate.length > 0) {
                _itemToUpdate[0]!.resourceQTY = ji.resourceQTY
              }
            }
          }
        })
      }
    },
    deleteResourcesFromJIAndTrucking: (state, action: PayloadAction<DeleteResourcesFromJIAndTrucking>) => {
      const { truckingResources, unix, locationIndex } = action.payload;
      if (truckingResources != null && truckingResources?.length > 0) {
        truckingResources.forEach((trToDelete, i) => {
          if (state.schedule?.locations?.[trToDelete.locationIndex!] == null) { return }
          if (state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix] == null) { return }

          state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].trucking.truckingResources.forEach((tr, index) => {
            if (tr?.operationType === trToDelete?.operationType) {
              delete state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].trucking.truckingResources[index];
            }
          })

          state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].labor.jobInfos.forEach((ji, index) => {
            if (ji != null && (ji?.resourceID === "PAV" || ji?.resourceID === "ACP")) {
              delete state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].labor.jobInfos[index];
              delete state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].selectedResources[ji?.resourceID];
            }
          })

          state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].paving.pavingResources?.forEach((pav, index) => {
            if (pav != null) {
              delete state.schedule?.locations?.[trToDelete.locationIndex!].daysByUnix[unix].paving.pavingResources[index];
            }
          })
        })
      }
      if (truckingResources != null && truckingResources.length > 0) {
        for (let i = 0; i < truckingResources?.length; i++) {
          const path = state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix];
          if (path.trucking.truckingResources.length > 0) {
            const resourcesWithoutPaving = path.trucking.truckingResources.filter(tr => tr?.operationType !== "paving");

            if (resourcesWithoutPaving.length > 0) {
              let qty = resourcesWithoutPaving
                ?.map(t => t?.qty)
                ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;
              let trkResource = state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].equip.jobInfos.filter(tr => tr?.resourceID === "TRK")[0];
              trkResource!.resourceQTY! = qty;
            }
            else {
              state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].equip.jobInfos.forEach((ji, index) => {
                if (ji != null && ji?.resourceID === "TRK") {
                  delete state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].equip.jobInfos[index];
                  delete state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].selectedResources[ji?.resourceID];
                }
              })
            }
          }
          else {
            state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].equip.jobInfos.forEach((ji, index) => {
              if (ji != null && ji?.resourceID === "TRK") {
                delete state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].equip.jobInfos[index];
                delete state.schedule?.locations?.[truckingResources[i].locationIndex!].daysByUnix[unix].selectedResources[ji?.resourceID];
              }
            })
          }
        }
      }

      if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix] != null) {
        if (Object.values(state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.labor?.jobInfos).length === 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix].labor.jobInfos = [];
        }
        if (Object.values(state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.equip?.jobInfos).length === 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos = [];
        }
        if (Object.values(state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving.pavingResources).length === 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = [];
        }
        if (Object.values(state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking.truckingResources).length === 0) {
          state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];
        }
      }
    },

    addResourcesToDay: (state, action: PayloadAction<AddResourcesToDay>) => {
      const { locationIndex, unix, resources } = action.payload;

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation[locationIndex][unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["true"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }

      resources?.forEach(resource => {
        if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
          return;
        }

        state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources[resource?.resourceID as string] = true;

        if (resource?.resourceType === "Labor") {
          state.schedule.locations?.[locationIndex].daysByUnix[unix].labor.jobInfos.push({
            pmpe: state.jobNumberInformations?.projectManager,
            jobName: state.jobNumberInformations?.jobName,
            date: unix,
            foreman: " ",
            description: " ",
            resourceID: resource.resourceID,
            superIntendent: state.jobNumberInformations?.superIntendentName?.length === 0 ? " " : state.jobNumberInformations?.superIntendentName,
            resourceType: resource.resourceType,
            additionalResourcesComments: " ",
            searchGSI_JobNumber: state.start.jobNumber as number,
            locationIndex: locationIndex,
            areaManager: state.jobNumberInformations?.areaManager,
            resourceName: resource.resourceDescription,
            resourceQTY: resource.QTYNeeded,
          } as JobInfo)
        }

        if (resource?.resourceType === "Equipment") {
          state.schedule.locations?.[locationIndex].daysByUnix[unix].equip.jobInfos.push({
            pmpe: state.jobNumberInformations?.projectManager,
            jobName: state.jobNumberInformations?.jobName,
            date: unix,
            foreman: " ",
            description: " ",
            resourceID: resource.resourceID,
            superIntendent: state.jobNumberInformations?.superIntendentName?.length === 0 ? " " : state.jobNumberInformations?.superIntendentName,
            resourceType: resource.resourceType,
            additionalResourcesComments: " ",
            searchGSI_JobNumber: state.start.jobNumber as number,
            locationIndex: locationIndex,
            areaManager: state.jobNumberInformations?.areaManager,
            resourceName: resource.resourceDescription,
            resourceQTY: resource.QTYNeeded,
          } as JobInfo)
        }
      })
    },
    addDescriptionToDay: (state, action: PayloadAction<AddDescriptionToDay>) => {
      const { locationIndex, unix, description } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }
      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation[locationIndex][unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["true"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("true")) {
        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].description = description;
    },
    locationInfoChange: (state, action: PayloadAction<LocationInfo>) => {
      const { locationIndex, activityDescription, location } = action.payload;
      if (state.schedule?.locations?.[locationIndex] == null) { return }
      if (state.modifiedLocation[locationIndex] == null) {
        state.modifiedLocation[locationIndex] = locationIndex
      }

      if (state.modifiedLocationInfo[locationIndex] == null) {
        state.modifiedLocationInfo[locationIndex] = {};
      }

      if (activityDescription != null) {
        state.schedule.locations[locationIndex].activityDescription = activityDescription;
        state.modifiedLocationInfo[locationIndex].activityDescription = activityDescription;
        return
      }
      if (location != null) {
        state.schedule.locations[locationIndex].name = location;
        state.modifiedLocationInfo[locationIndex].locationName = location;
        return
      }
    },
    addAdditionalResourceCommentsToDay: (state, action: PayloadAction<AddAdditionalResourceCommentsToDay>) => {
      const { locationIndex, unix, additionalResourceComments } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation[locationIndex][unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["true"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix].includes("true")) {
        state.modifiedDayByLocation?.[locationIndex]?.[unix]?.push("true");
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].additionalResourceComments = additionalResourceComments;
    },
    addTruckingResourcesToDay: (state, action: PayloadAction<AddTruckingResourcesToDay>) => {
      const { locationIndex, unix, truckingResources } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }
      state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = truckingResources;

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["trucking"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
        state.modifiedDayByLocation[locationIndex][unix].push("trucking");
      }
    },

    addTruckingResourcesToDayFromPavingManagement: (state, action: PayloadAction<AddTruckingResourcesToDay>) => {
      const { locationIndex, unix, truckingResources } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      let nonPavingRes = state.schedule.locations?.[locationIndex]?.daysByUnix[unix].trucking.truckingResources.filter(tr => tr?.operationType !== "paving")

      state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];

      nonPavingRes.forEach(tr => {
        state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
      })

      truckingResources.forEach(tr => {
        state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.push(tr);
      })

      let qtySum = state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources
        .map(tr => tr?.qty)
        .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

      const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]
      const truckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")
      if (truckingJobInfos != null && truckingJobInfos.length > 0) {
        if (truckingJobInfos[0] !== null) {
          truckingJobInfos[0].resourceQTY = qtySum;
        }
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["TRK"] = true;

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["trucking"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
        state.modifiedDayByLocation[locationIndex][unix].push("trucking");
      }

    },
    removeTruckingResourcesToDay: (state, action: PayloadAction<RemoveTruckingResourcesToDay>) => {
      const { locationIndex, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["trucking"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
        state.modifiedDayByLocation[locationIndex][unix].push("trucking");
      }
    },

    removeNonPavingTruckingResourcesToDay: (state, action: PayloadAction<RemoveTruckingResourcesToDay>) => {
      const { locationIndex, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      let pavTrkRes = state.schedule.locations?.[locationIndex]?.daysByUnix[unix].trucking.truckingResources.filter(tr => tr?.operationType === "paving");

      state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];

      pavTrkRes.forEach(p => {
        state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.push(p);
      })

      let qtySum = state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources
        .map(tr => tr?.qty)
        .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

      const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]
      const truckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")
      if (truckingJobInfos != null && truckingJobInfos.length > 0) {
        if (truckingJobInfos[0] !== null) {
          truckingJobInfos[0].resourceQTY = qtySum;
        }
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["trucking"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
        state.modifiedDayByLocation[locationIndex][unix].push("trucking");
      }
    },


    removePavingTruckingResourcesToDay: (state, action: PayloadAction<RemoveTruckingResourcesToDay>) => {
      const { locationIndex, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      let nonPavTrkRes = state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.filter(tr => tr?.operationType !== "paving");

      state.schedule.locations[locationIndex].daysByUnix[unix].trucking.truckingResources = [];

      nonPavTrkRes.forEach(p => {
        state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources.push(p);
      })

      let qtySum = state.schedule.locations?.[locationIndex].daysByUnix[unix].trucking.truckingResources
        .map(tr => tr?.qty)
        .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;

      const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]
      const truckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")
      if (truckingJobInfos != null && truckingJobInfos.length > 0) {
        if (truckingJobInfos[0] !== null) {
          truckingJobInfos[0].resourceQTY = qtySum;
        }
      }

      if (qtySum === 0) {
        let nonTrkRes = state.schedule.locations?.[locationIndex].daysByUnix[unix].equip.jobInfos.filter(j => j?.resourceID !== "TRK");
        state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos = [];
        nonTrkRes?.forEach(tr => {
          state.schedule.locations?.[locationIndex].daysByUnix[unix].equip.jobInfos.push(tr);
        })
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["trucking"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("trucking")) {
        state.modifiedDayByLocation[locationIndex][unix].push("trucking");
      }

      if (state.schedule.locations[locationIndex].daysByUnix[unix].equip.jobInfos.length === 0) {
        state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["TRK"] = false;
      }

    },
    addPavingResourcesToDay: (state, action: PayloadAction<AddPavingResourcesToDay>) => {
      const { locationIndex, unix, pavingResources } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = [];

      pavingResources.forEach(pav => {
        state.schedule.locations?.[locationIndex].daysByUnix[unix].paving.pavingResources.push(pav);
      })

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["paving"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("paving") && state.modifiedDayByLocation[locationIndex][unix]) {
        state.modifiedDayByLocation[locationIndex][unix].push("paving");
      }
    },

    updateShiftToPavingResources: (state, action: PayloadAction<AddPavingResourcesToDay>) => {
      const { locationIndex, unix, pavingResources } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = [];
      state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = pavingResources;

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["paving"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("paving") || state.modifiedDayByLocation[locationIndex][unix]) {
        state.modifiedDayByLocation[locationIndex][unix].push("paving");
      }
    },

    removePavingResourcesToDay: (state, action: PayloadAction<RemoveTruckingResourcesToDay>) => {
      const { locationIndex, unix } = action.payload;

      if (state.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix] == null) {
        return;
      }

      if (state.modifiedDayByLocation[locationIndex] == null) {
        state.modifiedDayByLocation[locationIndex] = {}
      }

      state.schedule.locations[locationIndex].daysByUnix[unix].paving.pavingResources = [];

      if (state.modifiedDayByLocation?.[locationIndex]?.[unix]?.length === 0 || state.modifiedDayByLocation?.[locationIndex]?.[unix] == null) {
        state.modifiedDayByLocation[locationIndex][unix] = ["paving"];
      }

      if (!state.modifiedDayByLocation[locationIndex][unix]?.includes("paving")) {
        state.modifiedDayByLocation[locationIndex][unix].push("paving");
      }
    },
    addTruckingQTYFromPaving: (state, action: PayloadAction<AddTruckingQTYFromPavingToDay>) => {
      const { locationIndex, unix, qty } = action.payload;
      if (state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix] == null) { return }
      const day = state.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]
      const truckingJobInfos = day.equip.jobInfos.filter(ji => ji?.resourceID === "TRK")
      if (truckingJobInfos.length > 0) {
        if (truckingJobInfos[0] == null) { return }
        truckingJobInfos[0].resourceQTY = qty;
        return;
      }
      const jobInfos = day.equip.jobInfos;
      for (let i = 0; i < 10; i++) {
        if (jobInfos[i] != null) { continue; }
        jobInfos[i] = {
          pmpe: state.jobNumberInformations?.projectManager,
          jobName: state.jobNumberInformations?.jobName,
          date: unix,
          foreman: " ",
          description: " ",
          resourceID: "TRK",
          resourceType: "Equipment",
          additionalResourcesComments: " ",
          searchGSI_JobNumber: state.start.jobNumber as number,
          locationIndex: locationIndex,
          areaManager: state.jobNumberInformations?.areaManager,
          resourceName: "Trucking",
          resourceQTY: qty,
        } as JobInfo
        break;
      }
      state.schedule.locations[locationIndex].daysByUnix[unix].selectedResources["TRK"] = true;
    },

    getWeatherData: (state, action: PayloadAction<any>) => {
      const forecast: Forecast[] = action.payload
      state.forecast = forecast;
      const locations = state.schedule.locations
      Object.keys(locations).forEach(l => {
        const locationsKey = parseInt(l);
        const days = locations[locationsKey].daysByUnix
        Object.keys(days).forEach(d => {
          const dayKey = parseInt(d)
          forecast.forEach(f => {
            const time = (moment.utc(f.startTime, "YYYY-MM-DD").unix()) * 1000
            if (time === dayKey) {
              state.schedule.locations?.[locationsKey].daysByUnix[dayKey].forecast.push(f);
            }
          })
        })
      })
    },

    addPavingModuleUser: (state, action: PayloadAction<AddPavingModuleUser>) => {
      const { name, email, groups, isInPavingSupers, isInGeneraPavingSupers, isDisabled } = action.payload;
      state.pavingModuleUser.name = name;
      state.pavingModuleUser.email = email;
      state.pavingModuleUser.groups = groups;
      state.pavingModuleUser.isInPavingSupers = isInPavingSupers;
      state.pavingModuleUser.isInGeneralPavingSupers = isInGeneraPavingSupers;
      state.pavingModuleUser.isDisabled = isDisabled;
    },

    addEquipmentToPavingModule: (state, action: PayloadAction<AddEquipmentToDay>) => {
      const { equipmentManagementResources } = action.payload;
      if (equipmentManagementResources == null || equipmentManagementResources.length === 0) { return }
      equipmentManagementResources?.forEach(e => {
        if (e != null) {
          e?.equipmentResources?.forEach(eq => {
            if (!state.pavingModule[e.date!]?.equipment.includes(eq))
              state.pavingModule[e.date!]?.equipment.push(eq);
          })

          let equips = state.pavingModule[e.date!].equipmentSubCategory.map(e => e?.eqSubcategoryDescription);
          e?.equipmentSubcategoryData?.forEach(eqs => {
            if (!equips.includes(eqs?.eqSubcategoryDescription)) {
              state.pavingModule[e.date!]?.equipmentSubCategory.push(eqs);
            }
          })
        }
      })
    },

    updateEquipmentToPavingModule: (state, action: PayloadAction<UpdateEquipmentToDay>) => {
      const { equipmentManagementResources, equipmentSubcategoryData, dates, jobNumber } = action.payload;

      let equipmentManagementResourcesDiffJN: EquipmentResourceManagementData[] = [];
      dates?.forEach(date => {
        state.pavingModule?.[date]?.equipment?.forEach(eq => {
          if (eq?.jobNumber !== jobNumber) {
            equipmentManagementResourcesDiffJN.push({
              eqID: eq?.eqID,
              eqName: eq?.eqName,
              eqSubcategoryDescription: eq?.eqSubcategoryDescription,
              eqSubcategoryID: eq?.eqSubcategoryID,
              jobName: eq?.eqName,
              jobNumber: eq?.jobNumber,
              locIndex: eq?.locIndex
            })
          }
        });

        if (state.pavingModule?.[date] != null && state.pavingModule?.[date]?.equipment != null) {
          state.pavingModule[date].equipment = [];
        }
        equipmentManagementResources?.forEach(eq => {
          if (!state.pavingModule?.[date]?.equipment.includes(eq))
            state.pavingModule?.[date]?.equipment.push(eq);
        })
        equipmentManagementResourcesDiffJN?.forEach(eq => {
          if (!state.pavingModule?.[date]?.equipment.includes(eq))
            state.pavingModule?.[date]?.equipment.push(eq);
        })

        let equipmentSubCategoryResourcesDiffJN: EquipmentManagementSubCategoryData[] = [];
        state.pavingModule[date]?.equipmentSubCategory?.forEach(eqs => {
          if (eqs?.jobNumber !== jobNumber) {
            equipmentSubCategoryResourcesDiffJN.push({
              eqSubcategoryDescription: eqs?.eqSubcategoryDescription?.trim(),
              eqSubcategoryID: eqs?.eqSubcategoryID,
              jobName: eqs?.jobName,
              jobNumber: eqs?.jobNumber,
              locIndex: eqs?.locIndex,
              qty: eqs?.qty
            })
          }
        })
        if (state.pavingModule?.[date] != null && state.pavingModule?.[date]?.equipmentSubCategory != null) {
          state.pavingModule[date].equipmentSubCategory = [];
        }
        equipmentSubcategoryData?.forEach(eqs => {
          if (!state.pavingModule[date]?.equipmentSubCategory.includes(eqs)) {
            state.pavingModule[date]?.equipmentSubCategory.push(eqs);
          }
        })
        equipmentSubCategoryResourcesDiffJN?.forEach(eqs => {
          if (!state.pavingModule[date]?.equipmentSubCategory.includes(eqs)) {
            state.pavingModule[date]?.equipmentSubCategory.push(eqs);
          }
        })
      })
    },

    updateEquipmentResourcesList: (state, action: PayloadAction<UpdateEquipmentResourcesList>) => {
      const { date, newDate, jobNumber, shift } = action.payload;

      // if in new date we don't jave equipment for the JN -> copy paste the equip list
      // check if in new date we have equipment for the jobNumber
      // if we have one item with same jobNumber and different shifts -> merge the equipment list
      // if we have item with same jn and shift -> overwrite
      // 1 item d -> 1 item d, 1 item n (same jn) -> overwrite d, union n

      const _equipmentResourcesFromDateWithSameJobNumber = state.pavingModule[date].equipment.filter(e => e?.jobNumber === jobNumber);
      const _equipmentResourcesSubcategoryFromDateWithSameJobNumber = state.pavingModule[date].equipmentSubCategory.filter(e => e?.jobNumber === jobNumber);
      const _equipmentResourcesFromDateWithDifferentJobNumber = state.pavingModule[date].equipment.filter(e => e?.jobNumber !== jobNumber);
      const _equipmentResourcesSubcategoryFromDateWithDifferentJobNumber = state.pavingModule[date].equipmentSubCategory.filter(e => e?.jobNumber !== jobNumber);
      const _equipmentResourcesNewDateWithDifferentJobNumber = state.pavingModule[newDate].equipment.filter(e => e?.jobNumber !== jobNumber);
      const _equipmentResourcesSubcategoryNewDateWithDifferentJobNumber = state.pavingModule[newDate].equipmentSubCategory.filter(e => e?.jobNumber !== jobNumber);
      const _equipmentResourcesNewDateWithSameJobNumber = state.pavingModule[newDate].equipment.filter(e => e?.jobNumber === jobNumber);
      const _equipmentResourcesSubcategoryNewDateWithSameJobNumber = state.pavingModule[newDate].equipmentSubCategory.filter(e => e?.jobNumber === jobNumber);
      let concatEquipments: Maybe<EquipmentResourceManagementData>[] | null | undefined = [];
      let concatEquipmentsSubcategory: Maybe<EquipmentManagementSubCategoryData>[] | null | undefined = [];

      const numberOfItemsOnDateSameJN = state.pavingModule[date].items.filter(i => i?.jobNumber === jobNumber).length;
      const numberOfItemsOnNewDateSameJN = state.pavingModule[newDate].items.filter(i => i?.jobNumber === jobNumber).length;
      const numberOfItemsOnNewDate = state.pavingModule[newDate].items.length;

      if (numberOfItemsOnNewDateSameJN === 0) {
        _equipmentResourcesFromDateWithSameJobNumber.forEach(eq => {
          state.pavingModule[newDate].equipment.push(eq);
        })

        _equipmentResourcesSubcategoryFromDateWithSameJobNumber.forEach(eqs => {
          state.pavingModule[newDate].equipmentSubCategory.push(eqs);
        })
      }

      for (let indexNewDate = 0; indexNewDate < numberOfItemsOnNewDate; indexNewDate++) {
        if (state.pavingModule[newDate].items[indexNewDate]?.jobNumber as number === jobNumber) {
          if (numberOfItemsOnNewDateSameJN === 1) {
            if (state.pavingModule[newDate].items[indexNewDate]?.shift === shift) {
              state.pavingModule[newDate].equipment = [];
              state.pavingModule[newDate].equipmentSubCategory = [];
              // equipment resources
              _equipmentResourcesFromDateWithSameJobNumber.forEach(eq => {
                state.pavingModule[newDate].equipment.push(eq);
              })
              _equipmentResourcesNewDateWithDifferentJobNumber.forEach(eq => {
                state.pavingModule[newDate].equipment.push(eq);
              })

              // equipment subcategory resources
              _equipmentResourcesSubcategoryFromDateWithSameJobNumber.forEach(eqs => {
                state.pavingModule[newDate].equipmentSubCategory.push(eqs);
              })
              _equipmentResourcesSubcategoryNewDateWithDifferentJobNumber.forEach(eqs => {
                state.pavingModule[newDate].equipmentSubCategory.push(eqs);
              })

            }
            if (state.pavingModule[newDate].items[indexNewDate]?.shift !== shift) {
              let equips: Maybe<EquipmentResourceManagementData>[] = [];
              let equipsSubcategory: Maybe<EquipmentManagementSubCategoryData>[] = [];

              concatEquipments = [..._equipmentResourcesFromDateWithSameJobNumber, ..._equipmentResourcesNewDateWithSameJobNumber];
              concatEquipmentsSubcategory = [..._equipmentResourcesSubcategoryFromDateWithSameJobNumber, ..._equipmentResourcesSubcategoryNewDateWithSameJobNumber];
              if (concatEquipments != null && concatEquipments.length > 0) {
                equips = concatEquipments.filter((v, i, a) => a.findIndex(t => (t?.eqID === v?.eqID && t?.eqID === v?.eqID)) === i)
              }
              state.pavingModule[newDate].equipment = [];
              _equipmentResourcesNewDateWithDifferentJobNumber.forEach(eq => {
                if (!state.pavingModule[newDate].equipment.includes(eq))
                  state.pavingModule[newDate].equipment.push(eq);
              })
              equips.forEach(eq => {
                if (!state.pavingModule[newDate].equipment.includes(eq))
                  state.pavingModule[newDate].equipment.push(eq);
              })

              if (concatEquipmentsSubcategory != null && concatEquipmentsSubcategory.length > 0) {
                equipsSubcategory = concatEquipmentsSubcategory
                  .filter((value, index, self) => self.findIndex(t => (t?.eqSubcategoryID === value?.eqSubcategoryID && (t?.qty! >= value?.qty!))) === index);
              }
              state.pavingModule[newDate].equipmentSubCategory = [];
              _equipmentResourcesSubcategoryNewDateWithDifferentJobNumber.forEach(eqs => {
                if (!state.pavingModule[newDate].equipmentSubCategory.includes(eqs))
                  state.pavingModule[newDate].equipmentSubCategory.push(eqs);
              })
              equipsSubcategory.forEach(eqs => {
                if (!state.pavingModule[newDate].equipmentSubCategory.includes(eqs))
                  state.pavingModule[newDate].equipmentSubCategory.push(eqs);
              })
            }
          }
          if (numberOfItemsOnNewDateSameJN === 2) {
            let equips: Maybe<EquipmentResourceManagementData>[] = [];
            let equipsSubcategory: Maybe<EquipmentManagementSubCategoryData>[] = [];
            concatEquipments = [..._equipmentResourcesFromDateWithSameJobNumber, ..._equipmentResourcesNewDateWithSameJobNumber];
            concatEquipmentsSubcategory = [..._equipmentResourcesSubcategoryFromDateWithSameJobNumber, ..._equipmentResourcesSubcategoryNewDateWithSameJobNumber];

            if (concatEquipments != null && concatEquipments.length > 0) {
              equips = concatEquipments.filter((value, index, self) => self.findIndex(t => (t?.eqID === value?.eqID && t?.eqID === value?.eqID)) === index)
            }
            state.pavingModule[newDate].equipment = [];
            _equipmentResourcesNewDateWithDifferentJobNumber.forEach(eq => {
              if (!state.pavingModule[newDate].equipment.includes(eq))
                state.pavingModule[newDate].equipment.push(eq);
            })
            equips.forEach(eq => {
              if (!state.pavingModule[newDate].equipment.includes(eq))
                state.pavingModule[newDate].equipment.push(eq);
            })

            if (concatEquipmentsSubcategory != null && concatEquipmentsSubcategory.length > 0) {
              equipsSubcategory = concatEquipmentsSubcategory
                .filter((value, index, self) => self.findIndex(t => (t?.eqSubcategoryID === value?.eqSubcategoryID && (t?.qty! >= value?.qty!))) === index);
            }
            state.pavingModule[newDate].equipmentSubCategory = [];
            _equipmentResourcesSubcategoryNewDateWithDifferentJobNumber.forEach(eqs => {
              if (!state.pavingModule[newDate].equipmentSubCategory.includes(eqs))
                state.pavingModule[newDate].equipmentSubCategory.push(eqs);
            })

            equipsSubcategory.forEach(eqs => {
              if (!state.pavingModule[newDate].equipmentSubCategory.includes(eqs))
                state.pavingModule[newDate].equipmentSubCategory.push(eqs);
            })
          }
        }
      }
      if (numberOfItemsOnDateSameJN === 1) {
        state.pavingModule[date].equipment = [];
        state.pavingModule[date].equipmentSubCategory = [];

        // equipment resources
        _equipmentResourcesFromDateWithDifferentJobNumber.forEach(eq => {
          state.pavingModule[date].equipment.push(eq);
        });

        // equipment subcategory resources
        _equipmentResourcesSubcategoryFromDateWithDifferentJobNumber?.forEach(eqs => {
          state.pavingModule[date].equipmentSubCategory.push(eqs);
        })
      }
    },

    saveChangesToItemFromFullDayPreview: (state, action: PayloadAction<SheetRowDayPreview>) => {
      const { grinder12ft, grinder4ft, grinder6ft, grinder7ft, extrawork, bookTruckVendor, oilTruk, uts, tph, broker, date, jobNumber, locatinIndex, nrOfTrucks, superIntendent, tonnage, typeOfTrucks, shift, material, plant, loadOutTime, updateJobInfoTable, updateMasterCrewSchedulePavingTable, updateMasterCrewScheduleTable, areaManager, jobMap, jobName, pavingForeman, pavingSuper, pavingSuperIntendent, pmpe, crewMakeLab, crewMakeOp, resourcesOp, resourcesLab, mixDesignApproval, rtsSupport, mixSubmital } = action.payload;

      if (state.fullDayPreview.length > 0) {
        state.fullDayPreview.forEach((item, i) => {
          if (item.date === date && item.jobNumber === jobNumber && item.locatinIndex === locatinIndex) {
            state.fullDayPreview.splice(i, 1);
          }
        })
      }

      state.fullDayPreview.push({
        grinder12ft,
        grinder4ft,
        grinder6ft,
        grinder7ft,
        bookTruckVendor,
        extrawork,
        oilTruk,
        tph,
        uts,
        broker,
        date,
        jobNumber,
        locatinIndex,
        nrOfTrucks,
        superIntendent,
        tonnage,
        typeOfTrucks,
        shift,
        material,
        plant,
        loadOutTime,
        updateJobInfoTable,
        updateMasterCrewSchedulePavingTable,
        updateMasterCrewScheduleTable,
        areaManager,
        jobMap,
        jobName,
        pavingForeman,
        pavingSuper,
        pavingSuperIntendent,
        pmpe,
        crewMakeLab,
        crewMakeOp,
        resourcesOp,
        resourcesLab,
        mixDesignApproval,
        rtsSupport,
        mixSubmital
      });
    },



    updateItemsFromPavingModule: (state, action: PayloadAction<SheetRowDayPreview>) => {
      const { broker, date, jobNumber, locatinIndex, nrOfTrucks, tonnage, typeOfTrucks, shift, resourcesLab, resourcesOp, tph, uts, oilTruk, extrawork, bookTruckVendor, grinder7ft, grinder6ft, grinder4ft, grinder12ft, mixDesignApproval, rtsSupport, mixSubmital } = action.payload;
      if (state.pavingModule[date]?.items != null) {
        state.pavingModule[date].items.forEach((item, i) => {
          if (item?.date === date && item?.jobNumber === jobNumber && item?.locationIndex === locatinIndex && item?.shift === shift) {
            item.broker = broker;
            item.nrOfTrucks = nrOfTrucks;
            item.tonnage = tonnage;
            item.typeOfTrucks = typeOfTrucks;
            item.resourcesLab = resourcesLab;
            item.resourcesOp = resourcesOp;
            item.tph = tph;
            item.uts = uts;
            item.oilTruk = oilTruk;
            item.extraWork = extrawork;
            item.bookTruckVendor = bookTruckVendor;
            item.grinder4ft = grinder4ft;
            item.grinder6ft = grinder6ft;
            item.grinder7ft = grinder7ft;
            item.grinder12ft = grinder12ft;
            item.mixDesignApproval = mixDesignApproval;
            item.rtsSupport = rtsSupport;
            item.mixSubmital = mixSubmital;
          }
        })
      }
      state.fullDayPreview = [];
    },

    updateJobMapToPavingModule: (state, action: PayloadAction<UpdateJobMap>) => {
      const { startDate, jobNumber, jobMap } = action.payload;
      Object.keys(state.pavingModule).forEach(date => {
        if (moment(parseInt(date)).format("YYYY.MM.DD") >= moment(startDate).format("YYYY.MM.DD")) {
          if (state.pavingModule[parseInt(date)]?.items != null) {
            state.pavingModule[parseInt(date)].items.forEach((item, i) => {
              if (item?.jobNumber === jobNumber) {
                item.jobMap = jobMap as string;
              }
            })
          }
        }
      })
    },

    addTruckingAndMaterialDatas: (state, action: PayloadAction<AddTruckingAndMaterialDatas>) => {
      const { truckingDatas, materialDatas } = action.payload;

      if (truckingDatas == null || truckingDatas?.length === 0) { return }

      state.truckingDatas = truckingDatas;
      state.materialDatas = materialDatas != null && materialDatas?.length > 0 ? materialDatas : [];
    },

    updateTruckingAndMaterialDatas: (state, action: any) => {
      const { truckingDatas, materialDatas, _materialDataResources, _truckingDataResources } = action.payload;

      if(_materialDataResources !== undefined){
      for (let i = 0; i < _materialDataResources.length; i++) {
        //@ts-ignore
        let index = materialDatas.findIndex(obj => obj.key === _materialDataResources[i].key);
        if (index !== -1) {
          materialDatas.splice(index, 1);
        }
      }
    }

    if(_truckingDataResources !== undefined){
      for (let i = 0; i < _truckingDataResources.length; i++) {
        //@ts-ignore
        let index = truckingDatas.findIndex(obj => obj.key === _truckingDataResources[i].key);
        if (index !== -1) {
          truckingDatas.splice(index, 1);
        }
      }
    }
      if (truckingDatas != null && truckingDatas.length > 0) {
        state.truckingDatas = truckingDatas;
      }

      if (materialDatas != null && materialDatas.length > 0) {
        state.materialDatas = materialDatas;
      }
    },

    updateForemanForItemsFromCalendar: (state, action: PayloadAction<UpdateForemanForItemsFromCalendar>) => {
      const { foremanNameForUpdate, foremanToDelete } = action.payload;

      if (foremanToDelete == null && foremanToDelete == null) return;

      Object.keys(state.pavingModule).forEach(date => {
        state.pavingModule[parseInt(date)].items.forEach(item => {
          if (item?.pavingForeman === foremanToDelete) {
            item.pavingForeman = foremanNameForUpdate;
          }
        })
      });
    },

    addRemoteEquipmentList: (state, action: PayloadAction<RemoteEquipmentList>) => {
      const { list } = action.payload;

      if (list == null) return;

      state.remoteEquipmentList = list;
    },

    cleanModifiedDayByLocation: (state) => {
      state.modifiedDayByLocation = {};
      state.modifiedLocationInfo = {};
      if (state.lastDay != null) {
        state.lastDay = null;
      }

      if (state.copyDay != null) {
        state.copyDay = null;
      }

      if (state.copyPaving != null) {
        state.copyPaving = null;
      }

      if (state.copyPavingJobInfo != null) {
        state.copyPavingJobInfo = null;
      }

      if (state.copyPavingTruckingResourceJobInfo != null) {
        state.copyPavingTruckingResourceJobInfo = null
      }

      if (state.copyPavingTruckingResources != null) {
        state.copyPavingTruckingResources = null
      }

      if (state.copyTrucking != null) {
        state.copyTrucking = null
      }

      if (state.copyTruckingJobInfo != null) {
        state.copyTruckingJobInfo = null
      }
    },

    setLockedDays: (state, action: PayloadAction<LockedDays>) => {
      const { lockedDays } = action.payload;

      state.lockedDays = lockedDays;
    },
  }
});


const myFetch = async (url: string) => {
  let headers = { Accept: "application/ld+json" }
  let options = { "headers": headers };
  const [error, response] = await to(fetch(url, options))
  if (error != null || response == null) { return }
  const [errorJson, responseJson] = await to(response.json());
  if (errorJson != null || responseJson == null) { return }
  return responseJson
}

export const getWeatherDataAsync = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const response = await myFetch(`${settings.weatherGridPoints}${state.app.jobNumberInformations?.coordinateLat},${state.app.jobNumberInformations?.coordinateLong}`);
  if (response == null) { return }
  const forecastURL: string = response?.forecast
  if (typeof forecastURL !== "string") { return }
  const responseForecast = await myFetch(forecastURL)
  if (responseForecast == null) { return }
  const periods = responseForecast?.periods
  if (periods == null) { return }
  dispatch(getWeatherData(periods));
};


export const {
  reducer: app,
  actions: {
    reset,
    login,
    addToken,
    refreshAuthToken,
    logout,
    start,
    init,
    addLocation,
    addCustomCrews,
    clearDay,
    pasteDay,
    undoDay,
    copyDay,
    copyTrucking,
    copyPaving,
    copyResourcesWithoutPav,
    JobChange,
    JobQTY,
    addResourcesToDay,
    addDescriptionToDay,
    locationInfoChange,
    addAdditionalResourceCommentsToDay,
    addJobNumberInformation,
    RemoveJob,
    removeToken,
    getWeatherData,
    addTruckingResourcesToDay,
    removeTruckingResourcesToDay,
    addPavingResourcesToDay,
    addTruckingQTYFromPaving,
    cleanModifiedDayByLocation,
    initPavingModule,
    addDataToPavingModule,
    movePavingItemToAnotherDate,
    addPavingModuleUser,
    changePavingItemForeman,
    updateJobNumberStatusFromDay,
    updateBlockedForemanFromDay,
    updateJobsNumbersRedBordered,
    addBlockedJobNumbersAndForemansToPavingModule,
    addDayStatusToPavingModule,
    updateDayStatusForPavingModule,
    removePavingResourcesToDay,
    addEquipmentSubcategories,
    addEquipmentResources,
    addEquipmentToPavingModule,
    updateEquipmentToPavingModule,
    updateEquipmentResourcesList,
    addTruckingAndMaterialDatas,
    saveChangesToItemFromFullDayPreview,
    updateItemsFromPavingModule,
    updateJobMapToPavingModule,
    addPavingFromPavingCalendarResources,
    addPavingFromPavingManagement,
    updateForemanForItemsFromCalendar,
    addIncompleteItemsToPavingModule,
    deleteResourcesFromJIAndTrucking,
    addTruckingResourcesToDayFromPavingManagement,
    removeNonPavingTruckingResourcesToDay,
    removePavingTruckingResourcesToDay,
    updateShiftToPavingResources,
    removeItemFromPavingModule,
    addRemoteEquipmentList,
    changeDoubledShift,
    setLockedDays,
    updateTruckingAndMaterialDatas
  },
} = appSlice;

interface StartPayload extends StartState { }

interface AuthPayload extends AuthState { }