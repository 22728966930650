import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import "./BookDatePaving.css";
import moment from "moment";
import { useImmer } from "use-immer";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { ITEMS_IN_PAVING_CALENDAR_QUERY } from "../../../../graphql/queries/ITEMS_IN_PAVING_CALENDAR_QUERY";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { PavingCalendar, Query, AddMasterCrewSchedulesPavingInput, JobInfoFullInput, BookYourDateOnPavingInput, PavingCalendarInput, AddMasterCrewSchedulesInput, Maybe, SuperIntendentPavingCalendar } from "../../../../graphql/schema-types";
import { BOOK_YOUR_DATE_ON_PAVING } from "../../../../graphql/mutations/BOOK_YOUR_DATE_ON_PAVING";
import to from "await-to-js";
import { FullScreenLoadingIndicator } from "../../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { CheckBox } from "../../../Form/CheckBox";
import { DELETE_BOOKED_DATE_ITEMS } from "../../../../graphql/mutations/DELETE_BOOKED_DATE_ITEMS";
import { TRUCKING_DATAS_QUERY } from "../../../../graphql/queries/TRUCKING_DATAS_QUERY";
import { CloseButton } from "../../../Form/CloseButton";
import { SelectMultipleDates } from "../SelectMultipleDates/SelectMultipleDates";
import "flatpickr/dist/themes/material_green.css";
import { GeneralWarningModal } from "../../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { ModalContext } from "../../../Modal/ModalContext/ModalContext";
import { addPavingFromPavingCalendarResources, deleteResourcesFromJIAndTrucking } from "../../../../redux/appSlice";
import { JOB_INFO_QUERY } from "../../../../graphql/queries/JOB_INFO_QUERY";
import { MASTER_CREW_SCHEDULES_QUERY } from "../../../../graphql/queries/MASTER_CREW_SCHEDULES_QUERY";
import { toMap } from "../../../../utils/toMap";
import { toMultiMap } from "../../../../utils/toMultiMap";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";

interface IBookDateProps {
  onClose?(): void;
  date: string;
  dateFormat: string;
  refetchItemInCalendar?(): void;
  dates?: Maybe<PavingCalendar>[] | undefined;
  redDates?: Maybe<PavingCalendar>[] | undefined;
  nrOfForemans: number | null;
  lockedItems3WeekRange: string[] | undefined;
}
interface DatesArray {
  dates: string[] | null | undefined
}

const getInitialBookState = (date: string): PavingCalendar & DatesArray => {
  return ({
    plant: "",
    tonnage: null,
    broker: "",
    nrOfTrucks: null,
    typeOfTrucks: "",
    material: "",
    operationType: "",
    shift: "",
    dates: [date]
  })
}

export const BookDatePavingComponent: FC<IBookDateProps> = props => {
  const [bookData, setBookData] = useImmer<PavingCalendar & DatesArray>(getInitialBookState(props.date));
  const [checkedItem, setCheckedItem] = useState<{ [key: number]: { checked: boolean, item: PavingCalendar } }>({});
  const [addButtonStatus, setAddButtonStatus] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const days = useSelector((state: RootState) => state.app.schedule.locations);
  const jobNumberInformations = useSelector((state: RootState) => state.app.jobNumberInformations);
  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);

  const { loading, error, data, refetch } = useQuery<Pick<Query, "itemsInPavingCalendar">>(ITEMS_IN_PAVING_CALENDAR_QUERY, {
    variables: { date: (moment.utc(props.date, "YYYY-MM-DD").unix()) * 1000 },
    skip: props.date == null,
    fetchPolicy: "no-cache"
  });

  const [getJobInfos, { loading: loadingJobInfo, error: errorJobInfo, data: datesJobInfo, refetch: refetchJobInfo }] = useLazyQuery<Pick<Query, "jobInfo">>(JOB_INFO_QUERY, {
    fetchPolicy: "no-cache"
  });

  const [getTrucking, { loading: loadingTrucking, error: errorTrucking, data: datesTrucking, refetch: refetchTrucking }] = useLazyQuery<Pick<Query, "masterCrewSchedules">>(MASTER_CREW_SCHEDULES_QUERY, {
    fetchPolicy: "no-cache"
  });

  const { data: trucking } = useQuery<Pick<Query, "truckingDatas">>(TRUCKING_DATAS_QUERY);

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(6, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  const [bookYourDateOnPaving] = useMutation(BOOK_YOUR_DATE_ON_PAVING, { onCompleted: refetch });

  const [deleteBookedDateItems, { loading: loadingDelete }] = useMutation(DELETE_BOOKED_DATE_ITEMS, { onCompleted: refetch });

  const materials = useSelector((state: RootState) => state.app.materialDatas);

  const modal = useContext(ModalContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      bookData.manager === "" ||
      bookData.nrOfTrucks == null ||
      bookData.nrOfTrucks === 0 ||
      bookData.nrOfTrucks.toString() === "" ||
      bookData.typeOfTrucks === "" ||
      bookData.shift === "" ||
      bookData.broker === "" ||
      bookData.plant === "" ||
      bookData.material === "" ||
      bookData.tonnage == null ||
      bookData.tonnage === 0 ||
      bookData.tonnage.toString() === "" ||
      bookData.dates?.length === 0) {
      setAddButtonStatus(true)
    }
    else {
      setAddButtonStatus(false);
    }
  }, [setAddButtonStatus, bookData])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bookData.material === "") return;
    const operationTypeForSelectedMaterial = materials.filter(material => material?.value === bookData.material)

    setBookData(draft => {
      draft.operationType = operationTypeForSelectedMaterial?.[0]?.operationType as string;
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookData.material])

  useEffect(() => {
    if (bookData.dates == null || bookData.dates.length === 0) return;

    const _datesSorted: string[] | undefined = [...bookData.dates].sort();

    getJobInfos({
      variables: {
        where:
        {
          jobNumber: jobNumberInformations?.jobNumber as number,
          startDate: (moment.utc(_datesSorted?.[0], "YYYY-MM-DD").unix()) * 1000,
          endDate: (moment.utc(_datesSorted?.[_datesSorted?.length - 1], "YYYY-MM-DD").unix()) * 1000
        }
      },
    });

    getTrucking({
      variables: {
        where:
        {
          jobNumber: jobNumberInformations?.jobNumber as number,
          startDate: (moment.utc(_datesSorted?.[0], "YYYY-MM-DD").unix()) * 1000,
          endDate: (moment.utc(_datesSorted?.[_datesSorted?.length - 1], "YYYY-MM-DD").unix()) * 1000
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookData.dates, getJobInfos, getTrucking])

  useEffect(() => {
    if (lockedDaysData == null) return;
    let lockedDays: string[] = checkForLockedDays();
    _lockedDays.forEach(ld => {
      if (!lockedDays.includes(ld)) lockedDays?.push(ld)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedDaysData])

  const getDates = useCallback(() => {
    return toMap(props.dates ?? [], pavingCalendar => pavingCalendar?.date!, () => true);
  }, [props.dates])

  const getDatesWithMoreThanThreeJN = () => {
    const limitItemsNumber = props.nrOfForemans != null && props.nrOfForemans > 0 ? props.nrOfForemans : 3;

    const groupedDates = toMultiMap(props.redDates ?? [], date => date?.date!);
    const dates = Object.keys(groupedDates)
      .map(date => groupedDates[date].map(d => d?.jobNumber))
      .map((gd, i) => gd.filter((item, index) => gd.indexOf(item) === index).length >= limitItemsNumber
        ? Object.keys(groupedDates)[i]
        : null
      )
    return dates.filter(d => d != null)
  }

  const shift = [
    {
      key: 1,
      value: "N"
    },
    {
      key: 2,
      value: "D"
    }
  ];

  const _brokerData = trucking?.truckingDatas
    ?.filter(td => td?.type === "Broker")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);


  if (_brokerData !== null && _brokerData !== undefined) {
    _brokerData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  const _trukData = trucking?.truckingDatas
    ?.filter(td => td?.type === "TruckType")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  if (_trukData !== null && _trukData !== undefined) {
    _trukData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  const _plantData = trucking?.truckingDatas
    ?.filter(td => td?.type === "Plant")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  const _materialData = materials
    ?.filter(m => m?.operationType === "paving")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  const onPlantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setBookData(draft => {
      draft.plant = _eValue;
    })
  }

  const onTonnageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;

    let tonnage: number | "" = parseInt(_eValue);
    tonnage = isNaN(tonnage) ? "" : tonnage;
    if (tonnage < 0) { return }

    if (tonnage.toString().length > 5) {
      return;
    }

    setBookData(draft => {
      draft.tonnage = tonnage as number;
    })
  }

  const onKeyPressTonnage = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (/^[0-9]+$/.test(e.key) === false) {
      e.preventDefault();
      return;
    }
  }

  const onBrokerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setBookData(draft => {
      draft.broker = _eValue;
    })
  }

  const onTypeOfTruckingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setBookData(draft => {
      draft.typeOfTrucks = _eValue;
    })
  }

  const onNumberOfTrucksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;

    let nrOfTrucks: number | "" = parseInt(_eValue);
    nrOfTrucks = isNaN(nrOfTrucks) ? "" : nrOfTrucks;
    if (nrOfTrucks < 0) { return }

    if (nrOfTrucks.toString().length > 5) {
      return;
    }

    setBookData(draft => {
      draft.nrOfTrucks = nrOfTrucks as number;
    })
  }

  const onKeyPressNumberOfTrucks = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (/^[0-9]+$/.test(e.key) === false) {
      e.preventDefault();
      return;
    }
  }

  const onCheckboxChange = (index: number, isChecked: boolean, item: PavingCalendar) => {
    const _checkedItem = { ...checkedItem };

    if (isChecked === false) {
      delete _checkedItem[index as number]
      setCheckedItem(_checkedItem);
      return;
    }

    setCheckedItem({ ...checkedItem, [index]: { checked: isChecked, item } })
  }

  const onMaterialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setBookData(draft => {
      draft.material = _eValue;
    })
  }
  const onShiftChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setBookData(draft => {
      draft.shift = value;
    })
  }

  const onDatesSelected = (dates: string[]) => {
    setBookData(draft => {
      draft.dates = dates;
    })
  }

  const onDelete = async () => {
    let valuesFromCheckedItem: PavingCalendarInput[] = [];

    Object.values(checkedItem)?.forEach(i => {
      let item = i.item;

      valuesFromCheckedItem.push({
        broker: item.broker,
        constant: item.constant,
        date: item.date,
        jobName: item.jobName,
        jobNumber: item.jobNumber,
        locationIndex: item.locationIndex,
        manager: item.manager,
        material: item.material,
        nrOfTrucks: item.nrOfTrucks,
        operationType: item.operationType,
        plant: item.plant,
        shift: item.shift,
        tonnage: item.tonnage,
        typeOfTrucks: item.typeOfTrucks,
        uID: item.uID
      });
    });

    dispatch(deleteResourcesFromJIAndTrucking({
      locationIndex: 1,
      unix: (moment.utc(props.date, "YYYY-MM-DD").unix()) * 1000,
      truckingResources: valuesFromCheckedItem,
    }));

    await to(deleteBookedDateItems({ variables: { data: valuesFromCheckedItem } }));
    setCheckedItem(() => ({}));
  }

  const onCloseWithoutSaving = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message="Are you sure you want to close without saving the resource(s)?"
        title="Save paving calendar resource"
        onConfirm={() => { props?.refetchItemInCalendar?.(); props.onClose?.(); setBookData(() => getInitialBookState(props.date)) }}
        onCancel={() => { }}
        yesNoButtons={true}
      />
    });
  }

  const onCloseModal = () => {
    if (bookData.broker !== "" ||
      bookData.plant !== "" ||
      bookData.tonnage != null ||
      bookData.nrOfTrucks != null ||
      bookData.material !== "" ||
      bookData.shift !== "") {
      onCloseWithoutSaving()
    } else {
      setBookData(() => getInitialBookState(props.date))
      props?.refetchItemInCalendar?.();
      props.onClose?.();
    }
  }

  const onDeleteClick = () => {
    const _date = (moment.utc(props.date, "YYYY-MM-DD").unix()) * 1000;
    const havePaving = datesJobInfo?.jobInfo.filter(ji => ji?.resourceID === "PAV" && ji.date === _date);

    if (checkForLockedDays().includes(props.date)) {
      modal?.openModal({
        element: <GeneralWarningModal
          title="Day or job number locked"
          message="The day or job number is locked. You cannot remove the item."
          yesNoButtons={false}
          onConfirm={() => { }}
          onCancel={() => { }}
        />
      })
    }
    else {
      if (havePaving != null && havePaving.length > 0) {
        modal?.openModal({
          element: <GeneralWarningModal
            title="Delete paving calendar resource"
            message="Are you sure you want to delete all paving resources from this day?"
            yesNoButtons={true}
            onConfirm={() => onDelete()}
            onCancel={() => { }}
          />
        })
      } else {
        modal?.openModal({
          element: <GeneralWarningModal
            title="Delete paving calendar resource"
            message="The data is incomplete and you cannot delete it from here. You have to go to the calendar and delete it from there!"
            yesNoButtons={false}
            onConfirm={() => { }}
            onCancel={() => { }}
          />
        })
      }
    }
  }

  const _datesWithJobInfoPav: string[] | undefined = datesJobInfo?.jobInfo
    ?.filter(ji => ji?.resourceID === "PAV" && ji.locationIndex === 1)
    ?.map(ji => ji?.date as string);

  const _datesWithJobInfoPavNoDuplicated = _datesWithJobInfoPav
    ?.filter((v, i) => _datesWithJobInfoPav.indexOf(v) === i)
    ?.map(date => moment(date).format("YYYY.MM.DD"));

  const _datesWithJobInfoTrk: string[] | undefined = datesJobInfo?.jobInfo
    ?.filter(ji => ji?.resourceID === "TRK" && ji?.locationIndex === 1)
    ?.map(ji => ji?.date as string);

  const _datesWithJobInfoTrkNoDuplicated = _datesWithJobInfoTrk
    ?.filter((v, i) => _datesWithJobInfoTrk.indexOf(v) === i)
    ?.map(date => moment(date).format("YYYY.MM.DD"));

  const _datesWithTruckingResourcesOpTypePaving: string[] | undefined = datesTrucking?.masterCrewSchedules
    ?.filter(tr => tr?.operationType === "paving" && tr.locationIndex === 1)
    ?.map(tr => tr?.date as string);

  const _datesWithTrkOpPavingNoDuplicated = _datesWithTruckingResourcesOpTypePaving
    ?.filter((v, i) => _datesWithTruckingResourcesOpTypePaving.indexOf(v) === i)
    ?.map(date => moment(date).format("YYYY.MM.DD"));

  const _datesWhereWeNeedToAddPavingManagement = bookData.dates?.filter(item => _datesWithJobInfoPavNoDuplicated != null && !_datesWithJobInfoPavNoDuplicated.includes(item));

  // dates where we need to add trucking resource on JobInfos table
  const _datesWhereWeNeedToAddTruckingManagementJI = bookData.dates?.filter(item => _datesWithJobInfoTrkNoDuplicated != null && !_datesWithJobInfoTrkNoDuplicated.includes(item));

  const _datesWhereWeNeedToAddTruckingResource = bookData.dates?.filter(item => _datesWithTrkOpPavingNoDuplicated != null && !_datesWithTrkOpPavingNoDuplicated.includes(item));

  const _datesWhereNeedToUpdateQtyJi = _datesWhereWeNeedToAddTruckingResource?.filter(item => _datesWhereWeNeedToAddTruckingManagementJI != null && !_datesWhereWeNeedToAddTruckingManagementJI.includes(item));

  const isSaveButtonDisabled = () => {
    if (days == null || Object.keys(days).length === 0 || props.date == null) return;

    const currentDay = moment.utc().format(props.dateFormat);

    if (props.date < currentDay) {
      return true
    }

    if (addButtonStatus === true) {
      return true
    }

    if (_datesWhereWeNeedToAddTruckingResource == null || _datesWhereWeNeedToAddTruckingResource.length === 0) {
      return true;
    }

    return false;
  }

  const onSubmit = async () => {
    const pavingData: AddMasterCrewSchedulesPavingInput[] = [];
    const truckingData: AddMasterCrewSchedulesInput[] = [];
    setIsLoading(true);

    if (_datesWhereWeNeedToAddTruckingResource != null && _datesWhereWeNeedToAddTruckingResource.length > 0) {
      _datesWhereWeNeedToAddTruckingResource?.forEach(date => {
        truckingData.push({
          date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
          locationIndex: 1,
          broker: bookData.broker as string,
          jobNumber: jobNumberInformations?.jobNumber as number,
          tableauGSI_Tableau: "tableau",
          searchGSI_JobNumber: jobNumberInformations?.jobNumber as number,
          loadSite: bookData.plant as string,
          material: bookData.material as string,
          notes: " ",
          operationType: bookData.operationType as string,
          qty: bookData.nrOfTrucks as number,
          shift: bookData.shift as string,
          type: bookData.typeOfTrucks as string,
          addedFromPaving: true,
          updated: false,
          timeStamp: moment().format()
        })
      });
    }

    if (_datesWhereWeNeedToAddPavingManagement != null && _datesWhereWeNeedToAddPavingManagement.length > 0) {
      _datesWhereWeNeedToAddPavingManagement
        ?.forEach(date => {
          pavingData.push({
            date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
            jobNumber: jobNumberInformations?.jobNumber as number,
            locationIndex: 1,
            material: bookData.material as string,
            notes: " ",
            qty: 1,
            searchGSI_JobNumber: jobNumberInformations?.jobNumber as number,
            shift: bookData.shift as string,
            mixSubmital: " ",
            tonnage: bookData.tonnage as number,
            oilTruck: "false",
            plant: bookData.plant as string,
            resourceDescription: "Foreman - Operator",
            resourceID: "OFM",
            resourceType: "Labor",
            bookTruckVendor: " ",
            rtsSupport: "GRC",
            tph: null,
            extraWork: " ",
            grinder4ft: null,
            grinder6ft: null,
            grinder7ft: null,
            grinder12ft: null,
            uts: " ",
            mixDesignApproval: "N",
            addedFromPaving: true,
            updated: false,
            timeStamp: moment().format()
          });
        })
    }

    const jobInfo: JobInfoFullInput[] = [];
    if (_datesWhereWeNeedToAddPavingManagement != null && _datesWhereWeNeedToAddPavingManagement.length > 0) {
      _datesWhereWeNeedToAddPavingManagement?.forEach(date => {
        jobInfo.push(
          {
            jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
            searchGSI_JobNumber: jobNumberInformations?.jobNumber,
            tableauGSI_Tableau: "tableau",
            resourceName: "Paving Crew",
            resourceQTY: 1,
            resourceID: "PAV",
            resourceType: "Labor",
            date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
            additionalResourcesComments: " ",
            areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
            description: " ",
            foreman: " ",
            jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
            locationIndex: 1,
            pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
            superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
            pavingForeman: " ",
            pavingSuperIntendent: " "
          },
          {
            jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
            searchGSI_JobNumber: jobNumberInformations?.jobNumber,
            tableauGSI_Tableau: "tableau",
            resourceName: "AC Paving Crew",
            resourceQTY: 1,
            resourceID: "ACP",
            resourceType: "Labor",
            date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
            additionalResourcesComments: " ",
            areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
            description: " ",
            foreman: " ",
            jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
            locationIndex: 1,
            pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
            superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
            pavingForeman: " ",
            pavingSuperIntendent: " "
          },
        );
      })
      if (_datesWhereWeNeedToAddTruckingManagementJI != null && _datesWhereWeNeedToAddTruckingManagementJI.length > 0) {
        _datesWhereWeNeedToAddTruckingManagementJI?.forEach(date => {
          jobInfo.push(
            {
              jobNumber_Date: parseInt(`${jobNumberInformations?.jobNumber}${(moment.utc(date, "YYYY-MM-DD").unix()) * 1000}`) / 1000,
              searchGSI_JobNumber: jobNumberInformations?.jobNumber,
              tableauGSI_Tableau: "tableau",
              resourceName: "Trucking",
              resourceQTY: bookData.nrOfTrucks as number,
              resourceID: "TRK",
              resourceType: "Equipment",
              date: (moment.utc(date, "YYYY-MM-DD").unix()) * 1000,
              additionalResourcesComments: " ",
              areaManager: jobNumberInformations?.areaManager == null ? " " : jobNumberInformations?.areaManager,
              description: " ",
              foreman: " ",
              jobName: jobNumberInformations?.jobName == null ? " " : jobNumberInformations?.jobName,
              locationIndex: 1,
              pmpe: jobNumberInformations?.projectManager == null ? " " : jobNumberInformations?.projectManager as string,
              superIntendent: jobNumberInformations?.superIntendentName == null ? " " : jobNumberInformations?.superIntendentName as string,
              pavingForeman: " ",
              pavingSuperIntendent: " "
            }
          );
        });
      }
    }

    const jobInfosToUpdate: JobInfoFullInput[] = [];
    if (_datesWhereNeedToUpdateQtyJi != null && _datesWhereNeedToUpdateQtyJi.length > 0) {
      const _datesWhereNeedToUpdateQtyJiTransformed = _datesWhereNeedToUpdateQtyJi.map(date => (moment.utc(date, "YYYY-MM-DD").unix()) * 1000)
      const _filteredJI = datesJobInfo?.jobInfo.filter(ji => ji?.resourceID === "TRK" && ji.locationIndex === 1);
      const itemsToUpdateJI = _filteredJI?.filter(ji => _datesWhereNeedToUpdateQtyJiTransformed.includes(ji?.date));

      itemsToUpdateJI?.forEach(item => {
        delete item?.["__typename"];
        const _qty = item?.resourceQTY as number + bookData.nrOfTrucks! as number;
        jobInfosToUpdate.push({ ...item, resourceQTY: _qty })
      });
    }

    // if trucking management has already an entry with the current job number, i do not need to save the information to schedules & paving management
    const data: BookYourDateOnPavingInput = {
      paving: {
        addPavingResources: pavingData.length === 0 ? null : pavingData
      },
      save: {
        jobInfos: {
          putJobInfo: jobInfo.length === 0 ? null : jobInfo
        }
      },
      trucking: {
        addTruckingResources: truckingData.length === 0 ? null : truckingData
      },
      jobInfosToUpdate: jobInfosToUpdate.length === 0 ? null : jobInfosToUpdate
    }

    dispatch(addPavingFromPavingCalendarResources({
      jobInfoResources: jobInfo,
      locationIndex: 1,
      pavingResources: pavingData,
      truckingResources: truckingData,
      jobInfoToUpdate: jobInfosToUpdate,
    }))

    const [, response] = await to(bookYourDateOnPaving({ variables: { data } }));

    if (response) {
      setBookData(() => getInitialBookState(props.date))
      props?.refetchItemInCalendar?.();
      refetchJobInfo();
      refetchTrucking();
    }

    setIsLoading(false);
  }

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    })
    let lockedDays = _lockedDays?.map(day => moment.utc(day!).format("YYYY.MM.DD"));
    return lockedDays;
  }

  let _lockedDays = checkForLockedDays()?.map(day => moment(day!).format("DD MM YYYY"));
  let _lockedItems3WeekRange = props.lockedItems3WeekRange?.map(day => moment(day!).format("DD MM YYYY"))

  useEffect(() => {
    refetchLockedDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlantChange, onTonnageChange, onKeyPressTonnage, onBrokerChange, onTypeOfTruckingChange, onNumberOfTrucksChange, onKeyPressNumberOfTrucks, onCheckboxChange,
    onMaterialChange, onShiftChange, onDatesSelected])

  return (
    <>
      {(loading || loadingJobInfo || loadingTrucking || loadingDelete) && <FullScreenLoadingIndicator />}
      {(error || errorJobInfo || errorTrucking) != null && <FullScreenErrorIndicator />}
      <div className="Manage_Book_Date_Paving_Main_Screen">
        <div className="Book_Date_Paving_Header">
          <div className="Title"> Date: {moment(props.date, props.dateFormat).format("MM/DD/YYYY")} {checkForLockedDays().includes(props.date) ? <i className="fa fa-lock" aria-hidden="true"></i> : null}</div>
          <CloseButton className="Close" onClick={() => onCloseModal()} />
        </div>
        <div className="middle-content">
          <div className="Wrap_Row">
            <div className="left_side">
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={bookData.typeOfTrucks as string} onChange={onTypeOfTruckingChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Type of Trucks
                    </option>
                    {
                      _trukData?.map((td, index) => (
                        <option value={td?.value as string} key={index}>
                          {td?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <input
                  value={bookData.nrOfTrucks?.toString?.() ?? ""}
                  className="inactive"
                  onChange={(e) => onNumberOfTrucksChange(e)}
                  onKeyPress={onKeyPressNumberOfTrucks}
                  placeholder="Number of Trucks"
                />
              </div>
              <div className="Row">
                <input
                  type="text"
                  value={bookData.tonnage?.toString?.() ?? ""}
                  onChange={(e) => onTonnageChange(e)}
                  onKeyPress={onKeyPressTonnage}
                  placeholder="Tonnage"
                />
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={bookData?.shift as string} onChange={onShiftChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Shift
                    </option>
                    {
                      shift?.map((s, index) => (
                        <option value={s?.value as string} key={index}>
                          {s?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="right_side">
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={bookData.broker as string} onChange={onBrokerChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Broker
                    </option>
                    {
                      _brokerData?.map((bd, index) => (
                        <option value={bd?.value as string} key={index}>
                          {bd?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={bookData.plant as string} onChange={onPlantChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Plant
                    </option>
                    {
                      _plantData?.map((pd, index) => (
                        <option value={pd?.value as string} key={index}>
                          {pd?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={bookData.material as string} onChange={onMaterialChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Material
                    </option>
                    {
                      _materialData?.map((md, index) => (
                        <option value={md?.value as string} key={index}>
                          {md?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row_SelectDates">
                {
                  !checkForLockedDays().includes(props.date) ?
                    <SelectMultipleDates
                      currentDates={bookData.dates}
                      onPickerChange={dates => onDatesSelected(dates)}
                      lockedDays={checkForLockedDays()}
                      dates={getDates()}
                      redDates={getDatesWithMoreThanThreeJN()}
                      lockedItems3WeekRange={_lockedItems3WeekRange}
                    />
                    : null
                }
              </div>
              <div className="Row_Slected_Dates">
                Selected Days: {bookData.dates?.map(s => moment(s as string, "YYYY.MM.DD").format('MM/DD/YYYY') + (bookData.dates != null && bookData?.dates.length as number < 2 ? " " : "; "))}
              </div>
            </div>
          </div>
          <div className="Action_Holder">
            <button className="Save_Resource Button" onClick={() => onSubmit()} disabled={isLoading || isSaveButtonDisabled() || ((checkForLockedDays().includes(props.date) && bookData.operationType === "paving"))}>Save</button>
          </div>
          <div className="Paving_Booked_Dates">
            <div className="Paving_Booked_Dates_Header">
              <div className="header_item">
                Job Number
              </div>
              <div className="header_item">
                Job Name
              </div>
              <div className="header_item">
                Manager
              </div>
              <div className="header_item">
                Tonnage
              </div>
              <div className="header_item">
                Type Of Truck
              </div>
              <div className="header_item">
                Nr Of Truck
              </div>
              <div className="header_item">
                Plant
              </div>
              <div className="header_item">
                Broker
              </div>
              <div className="header_item">
                Material
              </div>
              <div className="header_item">
                Shift
              </div>
            </div>
            <div className="Paving_Booked_Dates_Table">
              {
                data?.itemsInPavingCalendar.map((tr, index) => (
                  <div className={tr?.shift === "D" ? "Paving_Booked_Dates_Line" : "Paving_Booked_Dates_Line_Night"} key={index}>
                    <CheckBox
                      onChange={(e) => { onCheckboxChange(index, e.target.checked, tr!) }}
                      checked={checkedItem?.[index]?.checked || false}
                      disabled={tr?.jobNumber === jobNumberInformations?.jobNumber ? false : true}
                    />
                    <div className="header_item">
                      {tr?.jobNumber}
                    </div>
                    <div className="header_item">
                      {tr?.jobName}
                    </div>
                    <div className="header_item">
                      {tr?.manager}
                    </div>
                    <div className="header_item">
                      {tr?.tonnage}
                    </div>
                    <div className="header_item">
                      {tr?.typeOfTrucks}
                    </div>
                    <div className="header_item">
                      {tr?.nrOfTrucks}
                    </div>
                    <div className="header_item">
                      {tr?.plant}
                    </div>
                    <div className="header_item">
                      {tr?.broker}
                    </div>
                    <div className="header_item">
                      {tr?.material}
                    </div>
                    <div className="header_item">
                      {tr?.shift}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="Actions_Holder">
            <button className="Cancel_Book_Paving Button" onClick={props.onClose}>Cancel</button>
            <button className="Remove_Item_Paving Button" onClick={() => onDeleteClick()} disabled={Object.keys(checkedItem).length === 0}>Remove Item</button>
          </div>
        </div>
      </div>
    </>
  );
}