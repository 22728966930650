import React, { FC, memo } from 'react';
import Day from './Day';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { ScrollSyncNode } from 'scroll-sync-react';
import LeftHeader from './LeftHeader';
import { WeatherContentData, WeatherData } from '../graphql/schema-types';

interface IScheduleTableProps {
  locationIndex: number;
  weatherContent: WeatherContentData;
  weatherValues: WeatherData[];
}

const ScheduleTable: FC<IScheduleTableProps> = props => {
  const days = useSelector((state: RootState) => state.app.schedule.locations[props.locationIndex].days);
  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);


  const superIntendent = useSelector((state: RootState) => state.app.jobNumberInformations?.superIntendentName);
  const PMPE = useSelector((state: RootState) => state.app.jobNumberInformations?.projectManager);
  const jobName = useSelector((state: RootState) => state.app.jobNumberInformations?.jobName);

  return (
    <>
      <div id={`${props.locationIndex}`} style={{ height: "1px" }} />
      <ScrollSyncNode>
      <div className="Table" style={{ display: "flex", justifyContent: "flex-start" }}>
        <LeftHeader
          jobName={jobName as string}
          jobNumber={jobNumber}
          superIntendent={superIntendent === "" || superIntendent == null ? "missing" : superIntendent}
          locationIndex={props.locationIndex}
          PMPE={PMPE === "" || PMPE == null ? "missing" : PMPE}
        />
        
          <div className="Scroll_table" style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", overflow: 'auto', overflowY: 'hidden' }}>
            {days.map((day, index) =>
              <div key={index} style={{ display: "inline-block" }} >
                <Day key={`${props.locationIndex}-${day}`} locationIndex={props.locationIndex} unix={day} weatherContent={props?.weatherContent as WeatherContentData} weatherValues={props?.weatherValues as WeatherData[]} />
              </div>
            )}
          </div>
        

        </div>
      </ScrollSyncNode>
    </>
  )
};

export default memo(ScheduleTable);